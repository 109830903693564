import React, { useEffect, useState } from 'react';
import { useSeason } from './SeasonContext';
import rankIconDictionary from './data/RankIcons';
import iconDictionary from './data/Icons';
import Flag from 'react-world-flags';
import roleIcon from './data/Role';
import CountryCustomSelect from './CountryCustomSelect';
import Cookies from 'js-cookie';
import axiosInstance from './backend/axiosInstance';
import "./MatchHistory.css";
import Select from 'react-select';
import { getRedGreenCellno0 } from './base/colorUtils';
import CustomInput from './base/CustomInput';
import { Link } from 'react-router-dom';

const LeaderboardComponent = () => {
  const { currentSeasonTime } = useSeason();
  const [accountData, setAccountData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({ value: 'euw', label: 'EUW' });
  const [selectedCountry, setSelectedCountry] = useState({ value: 'all', label: 'All' });
  const [selectedRole, setSelectedRole] = useState({ value: -1, label: 'All' });
  const [followedPlayers, setFollowedPlayers] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState('');
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);

  const roleOptions = [
    { value: -1, label: 'All' }, // Adjust "All Roles" to use null
    { value: 0, label: 'Top' },
    { value: 1, label: 'Jgl' },
    { value: 2, label: 'Mid' },
    { value: 3, label: 'Bot' },
    { value: 4, label: 'Sup' },
  ];

  const regionOptions = [
    { value: 'euw', label: 'EUW' },
    { value: 'kr', label: 'KR' },
    { value: 'jp', label: 'JP' },
    { value: 'oce', label: 'OCE' },
  ];

  useEffect(() => {
    document.title = "Leaderboard " +String(selectedRegion.value.toUpperCase()); // Setting the document title within useEffect

    if (selectedRegion) {
      const url = `account/?region=${selectedRegion.value}`;
      axiosInstance
        .get(url)
        .then(response => {
          setAccountData(response.data);
        })
        .catch(error => {
          console.error('Error fetching leagues:', error);
        });
    }
  }, [selectedRegion]);


  const handleFollowChange = (playerId) => {
    const userId = Cookies.get('id'); // Retrieve user ID from cookies
    const isFollowing = followedPlayers.has(playerId);
    const updatedFollowedPlayers = new Set(followedPlayers);

    const action = isFollowing ? 'unfollow' : 'follow';

    if (isFollowing) {
      updatedFollowedPlayers.delete(playerId);
    } else {
      updatedFollowedPlayers.add(playerId);
    }

    axiosInstance.post('follow/', {
      user_id: userId,
      player_id: playerId,
      action: action
    })
    .catch(error => console.error(`Error ${action === 'follow' ? 'following' : 'unfollowing'} player:`, error));

    setFollowedPlayers(updatedFollowedPlayers);
  };

  const fetchFollowedPlayers = async () => {
    const userId = Cookies.get('id'); // Retrieve user ID from cookies
    if (userId) {
      try {
        const response = await axiosInstance.get('follow/', {
          params: { user_id: userId }
        });
        const followedPlayers = new Set(response.data.map(player => player.player_id));
        setFollowedPlayers(followedPlayers);
      } catch (error) {
        console.error('Error fetching followed players:', error);
      }
    }
  };

  useEffect(() => {
    fetchFollowedPlayers();
  }, []);

  useEffect(() => {
    let filteredData = [...accountData];

    if (selectedCountry && selectedCountry.value !== 'all') {
      filteredData = filteredData.filter(account => account.country === selectedCountry.value);
    }
    if (selectedRole.value !== -1) {
      filteredData = filteredData.filter(account => account.role === selectedRole.value);
    }
    if (searchQuery) {
      filteredData = filteredData.filter(account =>
        account.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        account.lastSummonerName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (showFavoritesOnly) {
      filteredData = filteredData.filter(account => followedPlayers.has(account.id));
    }

    setSortedData(filteredData);
  }, [accountData, selectedCountry, selectedRole, searchQuery, showFavoritesOnly, followedPlayers]);

  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption);
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption);
  };

  const countryOptions = [
    { value: 'all', label: 'All' }, // Add the "All" option
    ...Array.from(new Set(accountData.map(account => account.country).sort()))
      .filter(code => code)
      .map(country => ({
        value: country,
        label: country,
      }))
  ];

  return (
    <div>
      <div className='filters' style={{justifyContent: 'space-evenly'}}>
        <div style={{display: "flex", alignItems: "center", margin: "10px", color: 'black'}}>
          <label htmlFor="region-select" style={{color: "white"}}>Region:</label>
          <Select
            id="region-select"
            value={selectedRegion}
            onChange={handleRegionChange}
            options={regionOptions}
          />
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
          <label htmlFor="search-input">Search:</label>
          <CustomInput
            id="search-input"
            type="text"
            value={searchQuery}
            style={{color: 'black'}}
            onChange={(event) => setSearchQuery(event.target.value)}
            placeholder="Search by name or account"
          />
        </div>
        <div style={{display: "flex", alignItems: "center", color: 'black'}}>
          <label htmlFor="role-select"  style={{color: "white"}}>Role:</label>
          <Select
            id="role-select"
            value={selectedRole}
            onChange={handleRoleChange}
            options={roleOptions}
          />
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
          <label htmlFor="country-select">Country:</label>
          <CountryCustomSelect
            options={countryOptions}
            selectedOption={selectedCountry}
            onOptionChange={handleCountryChange}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <label htmlFor="favorites-only-checkbox">
            FAV:
          </label>
          <input
            type="checkbox"
            className="custom-checkbox"
            id="favorites-only-checkbox"
            checked={showFavoritesOnly}
            onChange={(event) => setShowFavoritesOnly(event.target.checked)}
          />
        </div>
      </div>
      <div className='main-content'>
        <table style={{ border: "none",  width: "100%"}}>
          <thead style={{ backgroundColor: 'lightgray'}}>
            <tr>
              <th style={{ width: "5%", borderBottom: "4px solid black", paddingLeft: "10px" }}></th>
              <th style={{ width: "2%", borderBottom: "4px solid black" }}></th>
              <th style={{ width: "17%", borderBottom: "4px solid black", paddingLeft: "10px" }}>Name</th>
              <th style={{ width: "23%", borderBottom: "4px solid black", textAlign: "center" }}>Account</th>
              <th style={{ width: "10%", borderBottom: "4px solid black", textAlign: "center" }}>Role</th>
              <th style={{ width: "20%", borderBottom: "4px solid black", textAlign: "center" }}>Rank</th>
              <th style={{ width: "10%", borderBottom: "4px solid black", textAlign: "center" }}>Games</th>
              <th style={{ width: "5%", borderBottom: "4px solid black", textAlign: "center" }}></th>
              <th style={{ width: "10%", borderBottom: "4px solid black", textAlign: "center" }}></th>
            </tr>
          </thead>
          <tbody>
            {sortedData.filter((account) => account.tier !== "UNRANKED").map((account, index) => (
              <tr className={followedPlayers.has(account.id) ? 'leadearboard-row-active' : 'leadearboard-row'} key={account.id} style={{borderBottom: '2px solid white' }}>
                <td style={{ paddingLeft: "10px" }}>{index + 1}.</td>
                <td>
                  <Flag code={account.country} alt={account.country} style={{ width: '30px', height: '30px' }} />
                </td>
                <td style={{ paddingLeft: "10px" }}>
                  {account.data_level==="Info" ? (
                    <div>
                      {account.name}
                    </div>
                    ):(
                    <Link to={`/soloQ/${account.name}`}>
                      {account.name}
                    </Link>
                  )}
                  </td>
                <td>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>{account.lastSummonerName}</div>
                    <div>
                      <button
                        key={account.puuid}
                        style={{ width: "25px", height: "25px" }}
                        onClick={() => window.open(`https://na.op.gg/summoners/${account.opgg}/${account.lastSummonerName.replace(/#/g, '-')}`, '_blank')}
                        className="account-button"
                      >
                        <img style={{ width: "25px", height: "25px" }} src={iconDictionary["opgg"]} alt="opgg icon" />
                      </button>
                      <button
                        key={account.summoner_id}
                        style={{ width: "25px", height: "25px" }}
                        onClick={() => window.open(`https://www.deeplol.gg/summoner/${account.opgg}/${account.lastSummonerName.replace(/#/g, '-')}`, '_blank')}
                        className="account-button"
                      >
                        <img style={{ width: "25px", height: "25px" }} src={iconDictionary["deeplol"]} alt="deeplol icon" />
                      </button>
                    </div>
                  </div>
                </td>
                <td style={{ textAlign: "center" }}>
                  <img src={roleIcon[account.role]} style={{ width: "30px" }} alt="role icon" />
                </td>
                <td style={{ textAlign: "center" }}>
                  <img style={{ width: "30px" }} src={rankIconDictionary[account.tier]} alt='rank icon' /> {account.tier} {["MASTER", "CHALLENGER", "GRANDMASTER"].includes(account.tier) ? "" : account.ranks} {account.leaguePoints} LP
                </td>
                <td style={{ textAlign: "center" }}>
                  {account.wins} / {account.losses}
                  <div style={{ color: getRedGreenCellno0(account.wins / (account.wins + account.losses) * 100, 30, 70) }}>
                    &nbsp;{(account.wins / (account.wins + account.losses) * 100).toFixed(0)}%
                  </div>
                </td>
                <td style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    checked={followedPlayers.has(account.id)}
                    onChange={() => handleFollowChange(account.id)}
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  {account.data_level}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeaderboardComponent;
