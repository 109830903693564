import React from 'react';
import Select from 'react-select';
import Flag from 'react-world-flags';

const CountryCustomSelect = ({ options, selectedOption, onOptionChange }) => {
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      width: '200px', // Set the desired width here
    }),
    option: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
  };

  const customOption = (props) => {
    const { innerProps, label, data } = props;
    return (
      <div {...innerProps} className="custom-option" style={{ display: 'flex', alignItems: 'center', color: "black" }}>
        <Flag code={data.value} alt={data.value} style={{ width: '24px', height: '24px', marginRight: '8px' }} />
        {data.value}
      </div>
    );
  };

  return (
    <Select
      options={options}
      onChange={(selectedOption) => onOptionChange(selectedOption)}
      value={selectedOption}
      components={{ Option: customOption }}
      styles={customStyles}
    />
  );
};

export default CountryCustomSelect;
