import Tank from "../../classes_photos/Tank.png";
import Mage from "../../classes_photos/Mage.png";
import Marksman from "../../classes_photos/Marksman.png";
import Fighter from "../../classes_photos/Fighter.png";
import Assassin from "../../classes_photos/Assasin.png";
import Controller from "../../classes_photos/Controller.png";

import Specialist from "../../classes_photos/Specialist.png";
export const classesPhotos = {
    "Warden": Tank,
    "Vanguard": Tank,
    Tank,
    "Battlemage": Mage,
    "Burst": Mage,
    "Artillery": Mage,
    Mage,
    "Juggernaut":Fighter,
    "Diver": Fighter,
    Fighter,
    Marksman,
    "Skirmisher": Assassin,
    Assassin,
    Support:Controller,
    "Enchanter":Controller,
    "Catcher":Controller,
    Specialist
}