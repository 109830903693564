import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { championData, championPhotos } from './data/ChampionsIcon'; // Assuming championPhotos has the URLs for champion images
import axiosInstance from './backend/axiosInstance';
import React, { useEffect, useState } from 'react';
import CustomSelect from './base/CustomSelect';
import { useSeason } from './SeasonContext';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PickBanByPatchComponent = () => {
  const { currentSeasonTime } = useSeason();

  document.title = "Champions";
  const [selectedLeague, setSelectedLeague] = useState([]);
  const [filterLeague, setFilterLeague] = useState([]);
  const [leagueOption, setLeagueOption] = useState([]);

  const [championOccurrences, setChampionOccurrences] = useState({});
  const [matchData, setMatchData] = useState([]);

  const [hoveredChampionId, setHoveredChampionId] = useState(null);
  const [chartData, setChartData] = useState(null); // State for storing chart data

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const handleMouseEnter = debounce((championId) => {
    setHoveredChampionId(championId);
  }, 100);

  const handleMouseLeave = debounce(() => {
    setHoveredChampionId(null);
  }, 100);

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance
      .get('parsed_official_games/?start=' + start + "&end=" + end)
      .then((response) => {
        const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setMatchData(sortedMatchData);
        const uniqueLeague = [...new Set(response.data.map((match) => match.leagueName))];
        const formattedLeagueOptions = uniqueLeague.map((league) => ({
          value: league,
          label: league,
        }));
        setLeagueOption(formattedLeagueOptions);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [currentSeasonTime]);

  const handleLeagueChange = (selectedOptions) => {
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterLeague(leagueValues);
    setSelectedLeague(selectedOptions);
  };

  useEffect(() => {
    if (matchData.length > 0) {
      const filteredmatchData = matchData.filter((match) => {
        const isLeagueGood = selectedLeague.length === 0 || filterLeague.includes(match.leagueName);
        const isPatchGood = match.patch != null;
        return isLeagueGood && isPatchGood;
      });

      const uniquePatchesForLeague = [...new Set(filteredmatchData.map((match) => match.patch))]
        .sort((a, b) => {
          const partsA = a.split('.').map(Number);
          const partsB = b.split('.').map(Number);

          if (partsA[0] !== partsB[0]) {
            return partsA[0] - partsB[0];
          }
          return partsA[1] - partsB[1];
        });

      const data = {};
      uniquePatchesForLeague.forEach(patch => {
        data[patch] = countChampionOccurrences(filteredmatchData, patch);
      });
      setChampionOccurrences(data);

      prepareChartData(data, uniquePatchesForLeague);
    }
  }, [selectedLeague, filterLeague, matchData]);

  const prepareChartData = (data, uniquePatchesForLeague) => {
    const datasets = [];

    Object.keys(championData).forEach((championId) => {
      const presenceData = uniquePatchesForLeague.map((patch) => {
        const champions = data[patch] || [];
        const champion = champions.find(([id]) => id === championId);
        return champion ? parseFloat(champion[1].presence) : 0;
      });

      const hasNonZeroPresence = presenceData.some(presence => presence > 0);
      if (hasNonZeroPresence) {
        const pointStyles = uniquePatchesForLeague.map(() => {
          const img = new Image();
          img.src = championPhotos[championData[championId]];
          img.width = 20;
          img.height = 20;
          return img;
        });

        datasets.push({
          label: championData[championId], // Label with champion name
          data: presenceData,
          fill: false,
          borderColor: getRandomColor(),
          tension: 0.1,
          pointStyle: pointStyles, // Set the champion images as point styles
          pointRadius: 5, // Adjust the size of the point (icon)
        });
      }
    });
    setChartData({
      labels: uniquePatchesForLeague,
      datasets,
    });
  };



  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  function countChampionOccurrences(matchData, patch) {
    const championOccurrences = {};
    const wins = {};

    Object.keys(championData).forEach(championId => {
      if (championId !== undefined) {
        championOccurrences[championId] = { bans: 0, picks: 0 };
        wins[championId] = 0;
      }
    });

    matchData.filter(match => match.patch === patch).forEach(match => {
      for (let i = 1; i <= 5; i++) {
        if (match.patch) {
          const blueBanKey = `BB${i}`;
          const redBanKey = `RB${i}`;
          const bluePickKey = `BP${i}`;
          const redPickKey = `RP${i}`;

          const blueBanChampionId = match[blueBanKey];
          const redBanChampionId = match[redBanKey];
          const bluePickChampionId = match[bluePickKey];
          const redPickChampionId = match[redPickKey];

          if (blueBanChampionId) {
            if (championOccurrences[blueBanChampionId]) {
              championOccurrences[blueBanChampionId].bans++;
            } else {
              championOccurrences[blueBanChampionId] = { bans: 1, picks: 0 };
            }
          }

          if (redBanChampionId) {
            if (championOccurrences[redBanChampionId]) {
              championOccurrences[redBanChampionId].bans++;
            } else {
              championOccurrences[redBanChampionId] = { bans: 1, picks: 0 };
            }
          }

          if (bluePickChampionId) {
            if (championOccurrences[bluePickChampionId]) {
              championOccurrences[bluePickChampionId].picks++;
              if (match.winner === 100) {
                wins[bluePickChampionId]++;
              }
            } else {
              championOccurrences[bluePickChampionId] = { bans: 0, picks: 1 };
            }
          }

          if (redPickChampionId) {
            if (championOccurrences[redPickChampionId]) {
              championOccurrences[redPickChampionId].picks++;
              if (match.winner === 200) {
                wins[redPickChampionId]++;
              }
            } else {
              championOccurrences[redPickChampionId] = { bans: 0, picks: 1 };
            }
          }
        }
      }
    });

    Object.keys(championOccurrences).forEach(championId => {
      const picks = championOccurrences[championId]?.picks || 0;
      const bans = championOccurrences[championId]?.bans || 0;
      const totalGames = picks + bans;
      championOccurrences[championId].presence = ((totalGames / matchData.filter(match => match.patch === patch).length) * 100).toFixed(2);
    });

    const sortedChampionOccurrences = Object.entries(championOccurrences)
      .sort(([aKey, aValue], [bKey, bValue]) => parseFloat(bValue.presence) - parseFloat(aValue.presence));
    return sortedChampionOccurrences;
  }

  return (
    <div className='wrapper'>
      <div className='filters'>
        League
        <div className='league-chooser'>
          <CustomSelect options={leagueOption} selectedOption={selectedLeague} onOptionChange={handleLeagueChange} />
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        {Object.entries(championOccurrences).map(([patch, championsData]) => (
          <div key={patch} style={{ width: "100%" }}>
            <table style={{ backgroundColor: "white", color: "black", paddingLeft: "5px", paddingRight: "5px" }}>
              <thead>
                <tr>
                  <th>Patch {patch}</th>
                </tr>
              </thead>
              <tbody>
                {championsData.map(([championId, stats]) => {
                  if (stats.presence === 0) {
                    return false;
                  }
                  return (
                    <tr key={championId}>
                      <td
                        onMouseEnter={() => handleMouseEnter(championId)}
                        onMouseLeave={() => handleMouseLeave()}
                        style={{
                          backgroundColor: hoveredChampionId === championId ? '#718ae3' : 'transparent',
                          transition: 'background-color 0.3s',
                        }}
                      >
                        <img
                          loading="lazy"
                          src={championPhotos[championData[championId]]}
                          className="small-image"
                          alt="Champion"
                        />
                        {stats.presence}%
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ))}
      </div>
      <div className='filters'>
        League
        <div className='league-chooser'>
          <CustomSelect options={leagueOption} selectedOption={selectedLeague} onOptionChange={handleLeagueChange} />
        </div>
      </div>
      {chartData && (
        <Line
          style={{height: "100vh", width: "100vh"}}
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false
              },
              title: {
                display: true,
                text: 'Champion Presence by Patch',
              },
              tooltip: {
                mode: 'nearest', // Show tooltip for the nearest dataset
                intersect: false, // Ensure it shows even if the point isn't directly under the mouse
              },
            },
            onHover: (event, chartElement) => {
              if (chartElement.length > 0) {
                const datasetIndex = chartElement[0].datasetIndex;

                if (chartElement[0].element && chartElement[0].element.$context && chartElement[0].element.$context.chart) {
                  const chart = chartElement[0].element.$context.chart;

                  chart.data.datasets.forEach((dataset, index) => {
                    if (index === datasetIndex) {
                      dataset.borderWidth = 10; // Highlight the hovered line
                    } else {
                      dataset.borderWidth = 1; // Dim other lines
                    }
                  });
                  chart.update();
                }
              } else {
                if (event.chart) {
                  event.chart.data.datasets.forEach((dataset) => {
                    dataset.borderWidth = 1;
                  });
                  event.chart.update();
                }
              }
            }
          }}
        />
      )}
    </div>
  );
};

export default PickBanByPatchComponent;
