import React, { useEffect, useState, useRef  } from 'react';
import CustomSelect from './base/CustomSelect';
import "./Players.css"
import Chart from 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-annotation';
import { Link } from 'react-router-dom';
import { useSeason } from './SeasonContext';
import { getGreenCellColor, getRedGreenCell, getRedGreenCellno0, getRedCellColor } from './base/colorUtils';
import axiosInstance from './backend/axiosInstance';

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const ScatterPlot = ({ data, selectedPlayers }) => {
  // Extract x and y data for the scatter plot
  const [xCategory, setXCategory] = useState('CS'); // Default x-axis category
  const [yCategory, setYCategory] = useState('GoldEarned'); // Default y-axis category
  const [colorCriteria, setColorCriteria] = useState('Role');

  const xData = data.map(player => player[xCategory]);
  const yData = data.map(player => player[yCategory]);

  const categoryOptions = [
    { label: 'CS/M', value: 'CS' },
    { label: 'DMG/M', value: 'DamageDealt' },
    { label: 'G/M', value: 'GoldEarned' },
    { label: 'JGL%', value: 'JungleProximity' },
    { label: 'CSD@8', value: 'csDiff8' },
    { label: 'CSD@14', value: 'csDiff14' },
    { label: 'DMG%', value: 'damageShared' },
    { label: 'D@8', value: 'deaths8' },
    { label: 'D@14', value: 'deaths14' },
    { label: 'XP@8', value: 'expDiff8' },
    { label: 'XP@14', value: 'expDiff14' },
    { label: 'Time', value: 'gameDuration' },
    { label: 'GD@8', value: 'goldDiff8' },
    { label: 'GD@14', value: 'goldDiff14' },
    { label: 'G%', value: 'goldShared' },
    { label: 'K+A@8', value: 'killsAndAssists8' },
    { label: 'K+A@14', value: 'killsAndAssists14' },
  ];
  const colorOptions = [
  { label: 'Random', value: 'Random' },
  { label: 'By League Name', value: 'LeagueName' },
  { label: 'By Role', value: 'Role' },
  { label: 'By Team', value: 'Team' },
];
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = document.getElementById('scatter-plot').getContext('2d');
    let pointColors;
    let uniqueCategories;

    if (colorCriteria === 'Random') {
      pointColors = data.map(() => getRandomColor());
    } else {

      if (colorCriteria === 'LeagueName') {
        uniqueCategories = [...new Set(data.map((player) => player.leagueName))];
      } else if (colorCriteria === 'Role') {
        uniqueCategories = [...new Set(data.map((player) => player.Role))];
      } else if (colorCriteria === 'Team') {
        uniqueCategories = [...new Set(data.map((player) => player.teamName))];
      }

      const colorMap = new Map();
      pointColors = data.map((player) => {
        const category = colorCriteria === 'Role' ? player.Role : colorCriteria === 'Team' ? player.teamName : player.leagueName;
        if (!colorMap.has(category)) {
          colorMap.set(category, getRandomColor());
        }
        return colorMap.get(category);
      });


    }

    chartRef.current = new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: [
          {
            label: 'Scatter Plot',
            data: xData.map((x, i) => ({ x, y: yData[i], player: data[i].summonerName })),
            pointBackgroundColor: pointColors,
            pointBorderColor: (context) => {
              // Set point border color based on selection
              return selectedPlayers.includes(context.dataset.data[context.dataIndex].player) ? 'purple' : 'white';
            },
            pointBorderWidth: (context) => {
              // Set point border width based on selection
              return selectedPlayers.includes(context.dataset.data[context.dataIndex].player) ? 4 : 2;
            },
            pointRadius: (context) => {
              // Set point radius based on selection
              return selectedPlayers.includes(context.dataset.data[context.dataIndex].player) ? 12 : 6;
            },
          },
        ],
      },
      options: {
        scales: {
          x: {
            type: 'linear',
            position: 'bottom',
            title: {
              display: true,
              text: categoryOptions.find(option => option.value === xCategory).label,
              color: 'white',
            },
            grid: {
              color: 'white', // Set the grid lines color to white
            },
            ticks: {
              color: 'white', // Set the axis label text color to white
            },
          },
          y: {
            type: 'linear',
            position: 'left',
            title: {
              display: true,
              text: categoryOptions.find(option => option.value === yCategory).label,
              color: 'white',
            },
            grid: {
              color: 'white', // Set the grid lines color to white
            },
            ticks: {
              color: 'white', // Set the axis label text color to white
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: true, // Set this to true to make tooltips visible from the start
            mode: 'point',
            intersect: false,
            callbacks: {
              label: (context) => `${context.dataset.data[context.dataIndex].player}`,
            },
          },


        },
        legend: {
          display: true,
          labels: {
            display: true,
          },
        },
      },
    });


    chartRef.current.update();


  }, [data, xCategory, yCategory, colorCriteria, selectedPlayers]);

  return (
    <>
    <div style={{color: 'white', alignItems: "center", display: "flex", justifyContent: "center"}}>
      <div>
        <label htmlFor="x-category">X:</label>
        <select id="x-category" value={xCategory} onChange={(e) => setXCategory(e.target.value)}>
          {categoryOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="y-category">Y:</label>
        <select id="y-category" value={yCategory} onChange={(e) => setYCategory(e.target.value)}>
          {categoryOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="color-criteria">Color by:</label>
        <select id="color-criteria" value={colorCriteria} onChange={(e) => setColorCriteria(e.target.value)}>
          {colorOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
    <div style={{width: "100%"}}>
      <canvas id="scatter-plot"></canvas>
    </div>
    </>
  );
};


const Players = () => {
  document.title = "Players";
  const { currentSeasonTime } = useSeason();
  const [selectedRole, setSelectedRole] = useState([]);
  const [filterRole, setFilterRole] = useState([]);

  const [selectedLeague, setSelectedLeague] = useState([]);
  const [filterLeague, setFilterLeague] = useState([]);
  const [leagueOption, setLeagueOption] = useState([]);

  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const [ShowSelected, setShowSelected] = useState(false);
  const [playerData, setPlayerData] = useState([]);
  const roleOptions = [
    { value: 0, label: 'Top' },
    { value: 1, label: 'Jgl' },
    { value: 2, label: 'Mid' },
    { value: 3, label: 'Bot' },
    { value: 4, label: 'Sup' },
  ];

  const [sortedColumn, setSortedColumn] = useState('summonerName');
  const [sortDirection, setSortDirection] = useState('asc');

  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const [visibleColumns, setVisibleColumns] = useState({
    summonerName: true,
    killsAndAssists8: true,
    deaths8: true,
    goldDiff8: true,
    csDiff8: true,
    expDiff8: true,
    killsAndAssists14: true,
    deaths14: true,
    goldDiff14: true,
    csDiff14: true,
    expDiff14: true,
    damageShared: true,
    goldShared: true,
    JungleProximity: true,
    DamageDealt: true,
    GoldEarned: true,
    CS: true,
    kills: true,
    deaths: true,
    assists: true,
    KDA: true
  });
  const columnNames = {
    summonerName: "Player",
    killsAndAssists8: "K+A@8",
    deaths8: "D@8",
    goldDiff8: "GD@8",
    csDiff8: "CSD@8",
    expDiff8: "XPD@8",
    killsAndAssists14: "K+A@14",
    deaths14: "D@14",
    goldDiff14: "GD@14",
    csDiff14: "CSD@14",
    expDiff14: "XPD@14",
    damageShared: "DMG%",
    goldShared: "G%",
    JungleProximity: "JNG%",
    DamageDealt: "DMG/M",
    GoldEarned: "G/M",
    CS: "CS/M",
    kills: "K",
    deaths: "D",
    assists: "A",
    KDA: "KDA"
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleColumnToggle = (column) => {
    setVisibleColumns(prevColumns => ({
      ...prevColumns,
      [column]: !prevColumns[column]
    }));
  };


  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance
      .get('players/?start=' + start + "&end=" + end)
      .then(response => {
        setPlayerData(response.data);
        const uniquePatches = [...new Set(response.data.map((match) => match.patch))];
        const formattedPatchOptions = uniquePatches.sort().map((patch) => ({
          value: patch,
          label: patch,
        }));
        setPatchOption(formattedPatchOptions);
        const uniqueLeagues = [...new Set(response.data.map((match) => match.leagueName))];
        const formattedLeagueOptions = uniqueLeagues.sort().map((league) => ({
          value: league,
          label: league,
        }));
        setLeagueOption(formattedLeagueOptions);
      })
      .catch(error => {
        console.error('Error fetching player data:', error);
      });
  }, []);

  const getMaxValue = (property) => {
    return Math.max(...Object.values(playerAverages).map((player) => player[property]));
  };

  const getMinValue = (property) => {
    return Math.min(...Object.values(playerAverages).map((player) => player[property]));
  };

  const filteredPlayerData = playerData.filter((team) => {
    const isLeagueGood = selectedLeague.length === 0 || filterLeague.includes(team.leagueName);
    const isPatchGood = selectedPatch.length === 0 || filterPatch.includes(team.patch);
    const isRoleGood = selectedRole.length === 0 || filterRole.includes(team.Role);
    return isLeagueGood && isPatchGood && isRoleGood;
  });

  const playerAverages = filteredPlayerData.reduce((acc, player) => {
    if (!acc[player.summonerName]) {
      acc[player.summonerName] = {
        summonerName: player.summonerName,
        killsAndAssists8: 0,
        deaths8: 0,
        goldDiff8: 0,
        expDiff8: 0,
        csDiff8: 0,
        gameDuration: 0,
        killsAndAssists14: 0,
        deaths14: 0,
        goldDiff14: 0,
        csDiff14: 0,
        expDiff14: 0,
        damageShared: 0,
        goldShared: 0,
        JungleProximity: 0,
        Role: player.Role,
        leagueName: player.leagueName,
        teamName: player.teamName,
        DamageDealt: 0,
        GoldEarned: 0,
        CS: 0,
        kills: 0,
        deaths: 0,
        assists: 0,
        Records: 0,
        KDA: 0
      };
    }
    acc[player.summonerName].killsAndAssists8 += player.killsAndAssists8;
    acc[player.summonerName].deaths8 += player.deaths8;
    acc[player.summonerName].goldDiff8 += player.goldDiff8;
    acc[player.summonerName].csDiff8 += player.csDiff8;
    acc[player.summonerName].expDiff8 += player.expDiff8;
    acc[player.summonerName].gameDuration += player.gameDuration;
    acc[player.summonerName].killsAndAssists14 += player.killsAndAssists14;
    acc[player.summonerName].deaths14 += player.deaths14;
    acc[player.summonerName].goldDiff14 += player.goldDiff14;
    acc[player.summonerName].csDiff14 += player.csDiff14;
    acc[player.summonerName].expDiff14 += player.expDiff14;
    acc[player.summonerName].damageShared += player.damageShared*100;
    acc[player.summonerName].goldShared += player.goldShared*100;
    acc[player.summonerName].JungleProximity += player.JungleProximity*100;
    acc[player.summonerName].DamageDealt += player.DamageDealt;
    acc[player.summonerName].GoldEarned += player.GoldEarned;
    acc[player.summonerName].CS += player.CS;
    acc[player.summonerName].kills += player.kills;
    acc[player.summonerName].deaths += player.deaths;
    acc[player.summonerName].assists += player.assists;
    acc[player.summonerName].Records += 1;
    acc[player.summonerName].KDA = 0;
    return acc;
  }, {});

  Object.values(playerAverages).forEach(player => {
    const propertiesToRound = [
      'killsAndAssists8',
      'deaths8',
      'goldDiff8',
      'csDiff8',
      'expDiff8',
      'gameDuration',
      'killsAndAssists14',
      'deaths14',
      'goldDiff14',
      'csDiff14',
      'expDiff14',
      'damageShared',
      'goldShared',
      'JungleProximity',
      'DamageDealt',
      'GoldEarned',
      'CS',
      'kills',
      'deaths',
      'assists',
      "KDA"
    ];

    // Loop through each property and round the value
    propertiesToRound.forEach(property => {
      player[property] /= player["Records"];
      if (property==='DamageDealt' || property==='GoldEarned'  || property==='CS')
      {
        player[property] /= player["gameDuration"];
      }
      if (property==='KDA' )
      {
        player["KDA"] = (player.kills + player.assists) / Math.max(player.deaths, 1);
      }
    });
  });

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(columnName);
      setSortDirection('asc');
    }
  };

  const compareValues = (key, order = 'asc') => {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  };

  function getColorForCell(key, value, player) {
    switch (key) {
      case 'deaths':
        return getRedCellColor(value, getMaxValue("deaths"));
      case 'KDA':
        return getRedGreenCellno0(value,  getMinValue("KDA"), getMaxValue("KDA"));
      case 'assists':
        return getGreenCellColor(value, getMaxValue("assists"));
      case 'kills':
        return getGreenCellColor(value, getMaxValue("kills"));
      case 'deaths8':
        return getRedCellColor(value, getMaxValue("deaths8"));
      case 'killsAndAssists8':
        return getGreenCellColor(value, getMaxValue('killsAndAssists8'));
      case 'goldDiff8':
        return getRedGreenCell(value, getMinValue('goldDiff8'), getMaxValue("goldDiff8"));
      case 'csDiff8':
        return getRedGreenCell(value, getMinValue('csDiff8'), getMaxValue("csDiff8"));
      case 'expDiff8':
        return getRedGreenCell(value, getMinValue('expDiff8'), getMaxValue("expDiff8"));
      case 'killsAndAssists14':
        return getGreenCellColor(value, getMaxValue('killsAndAssists14'));
      case 'deaths14':
        return getRedCellColor(value, getMaxValue("deaths14"));
      case 'goldDiff14':
        return getRedGreenCell(value, getMinValue('goldDiff14'), getMaxValue("goldDiff14"));
      case 'csDiff14':
        return getRedGreenCell(value, getMinValue('csDiff14'), getMaxValue("csDiff14"));
      case 'expDiff14':
        return getRedGreenCell(value, getMinValue('expDiff14'), getMaxValue("expDiff14"));
      case 'damageShared':
        return getRedGreenCellno0(value, getMinValue('damageShared'), getMaxValue("damageShared"));
      case 'goldShared':
        return getRedGreenCellno0(value, getMinValue('goldShared'), getMaxValue("goldShared"));
      case 'JungleProximity':
        return getRedGreenCellno0(value, getMinValue('JungleProximity'), getMaxValue('JungleProximity'));
      case 'DamageDealt':
        return getRedGreenCellno0(value, getMinValue('DamageDealt'), getMaxValue('DamageDealt'));
      case 'GoldEarned':
        return getRedGreenCellno0(value, getMinValue('GoldEarned'), getMaxValue('GoldEarned'));
      case 'CS':
        return getRedGreenCellno0(value, getMinValue('CS'), getMaxValue('CS'));
      default:
        return 'white'; // Default no color
    }
  }

  function formatValue(value, key) {
    switch (key) {
      case 'summonerName':
      return (
        <Link to={`/player/${encodeURIComponent(value)}`} style={{ textDecoration: 'none', color: 'inherit', textAlign: 'left' }}>
          {value}
        </Link>
      );
      case 'killsAndAssists8':
      case 'killsAndAssists14':
      case 'deaths8':
      case 'deaths14':
      case 'kills':
      case 'deaths':
      case 'assists':
        return `${value.toFixed(2)}`;
      case 'goldDiff8':
      case 'goldDiff14':
        return `${value.toFixed(0)}`;   // Gold difference rounded to whole numbers with ' GD'
      case 'csDiff8':
      case 'csDiff14':
      case 'CS':
        return `${value.toFixed(2)}`;   // CS values formatted to two decimals with ' CS'
      case 'expDiff8':
      case 'expDiff14':
        return `${value.toFixed(0)}`;   // Experience points without decimals ' XP'
      case 'damageShared':
      case 'goldShared':
      case 'JungleProximity':
        return `${value.toFixed(2)}%`;
      case 'DamageDealt':
      case 'GoldEarned':
        return `${value.toFixed(0)}`;
      case 'KDA':
          return value.toFixed(2);
      default:
        return value.toString();           // Default, convert any value to string to handle null or undefined gracefully
    }
  }

  const sortedPlayerAverages = Object.values(playerAverages).sort(compareValues(sortedColumn, sortDirection));

    const handleRoleChange = (selectedOptions) => {
      const roleValues = selectedOptions.map(option => option.value);
      setFilterRole(roleValues)
      setSelectedRole(selectedOptions); // Make sure to pass as an array
    };

    const handleLeagueChange = (selectedOptions) => {
      const leagueValues = selectedOptions.map(option => option.value);
      setFilterLeague(leagueValues)
      setSelectedLeague(selectedOptions);
    };

    const handlePatchChange = (selectedOptions) => {
      const leagueValues = selectedOptions.map((option) => option.value);
      setFilterPatch(leagueValues);
      setSelectedPatch(selectedOptions);
    };

    const handlePlayerCheckboxChange = (summonerName) => {
      if (selectedPlayers.includes(summonerName)) {
        setSelectedPlayers(selectedPlayers.filter((name) => name !== summonerName));
      } else {
        setSelectedPlayers([...selectedPlayers, summonerName]);
      }
    };

    return (
        <div>
          <div className='filters'>
          League
            <div className='league-chooser'>
              <CustomSelect options={leagueOption} selectedOption={selectedLeague} onOptionChange={handleLeagueChange} />
            </div>
            Role
            <div className='role-chooser'>
              <CustomSelect options={roleOptions} selectedOption={selectedRole} onOptionChange={handleRoleChange} />
            </div>
            Patch
            <div className='short-chooser'>
              <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
            </div>
            {"Only Selected"}
              <label>
                <input
                  type="checkbox"
                  checked={ShowSelected}
                  onChange={() => setShowSelected(!ShowSelected)}
                />
              </label>
              <div style={{ position: 'relative' }}>
                <button onClick={toggleDropdown}>{" Toggle Columns "}</button>
                {isOpen && (
                  <div  style={{ position: 'absolute', top: '100%', left: 0, backgroundColor: 'white', boxShadow: '0px 8px 16px rgba(0,0,0,0.2)', zIndex: 1000 }}>
                    {Object.entries(visibleColumns).map(([key, visible]) => (
                      <label key={key} style={{ display: 'block', color: "black" }}>
                        <input
                          type="checkbox"
                          checked={visible}
                          onChange={() => handleColumnToggle(key)}
                        /> {columnNames[key]}
                      </label>
                    ))}
                  </div>
                )}
              </div>
          </div>
          <div style={{backgroundColor: 'white'}}>
            <div className='team-performance-table-container'>
              <table className='fancy-table-players'>
                <thead>
                  <tr>
                    <th></th>
                    {Object.entries(visibleColumns).map(([key, visible]) =>
                      visible ? <th key={key} onClick={() => handleSort(key)}>{columnNames[key]}</th> : null
                    )}
                  </tr>
                </thead>
                <tbody>
                  {sortedPlayerAverages.map(player => {
                    if (ShowSelected && !selectedPlayers.includes(player.summonerName)) {
                      return null; // Optionally hide entire rows
                    }
                    return (
                      <tr key={player.summonerName}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedPlayers.includes(player.summonerName)}
                            onChange={() => handlePlayerCheckboxChange(player.summonerName )}
                          />
                        </td>
                        {Object.entries(visibleColumns).map(([key, visible]) => {
                          if (!visible) return null;
                          const value = player[key];
                          return (
                            <td key={key} style={{ backgroundColor: getColorForCell(key, value, player) , textAlign: (key === "summonerName" ? "left" : "center")}}>
                              {formatValue(value, key)}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className='filters'>
          League
            <div className='league-chooser'>
              <CustomSelect options={leagueOption} selectedOption={selectedLeague} onOptionChange={handleLeagueChange} />
            </div>
            Role
            <div className='role-chooser'>
              <CustomSelect options={roleOptions} selectedOption={selectedRole} onOptionChange={handleRoleChange} />
            </div>
            Patch
            <div className='short-chooser'>
              <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
            </div>
          </div>
          <div style={{width: "99%"}}>
            {sortedPlayerAverages.length > 0 && (
              <ScatterPlot data={sortedPlayerAverages} selectedPlayers={selectedPlayers} />
            )}
          </div>

        </div>
    );
};

export default Players;
