import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomDateInput from './base/CustomInputCalendar';
import { useSeason } from './SeasonContext';
import axiosInstance from './backend/axiosInstance';
import LoadingComponent from './base/LoadingComponent';
import mapPhotsDictionary from './data/MapData';
import { TeamIcons } from './data/TeamIcons';
import { championData, championPhotos } from "./data/ChampionsIcon";
import CustomSelect from './base/CustomSelect';
import CustomInput from './base/CustomInput';


const objectivesDict = {
    dragon : {"x": 10000, "y": 10500},
    VoidGrub : {"x": 5000, "y": 4500},
    riftHerald : {"x": 5000, "y": 4500},
}

const TeamObjectivesComponent = () => {
  const { currentSeasonTime } = useSeason();
  const { teamName } = useParams();

  document.title = "Objective " + teamName;

  const [matchData, setMatchData] = useState([]);
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30); // Subtract 30 days
    return date;
  });

  const [endDate, setEndDate] = useState(new Date());
  const [mapWidth, setMapWidth] = useState((window.innerWidth)/4);
  const [playerPositions, setPlayerPositions] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const [selectedObjective, setSelectedObjective] = useState([]);
  const [filterObjective, setFilterObjective] = useState([]);
  const [objectiveOption, setObjectiveOption] = useState([]);

  const [selectedSide, setSelectedSide] = useState([]);
  const [filterSide, setFilterSide] = useState([]);
  const sideOption = [
    { value: "any", label: 'Any' },
    { value: "team1", label: 'Blue' },
    { value: "team2", label: 'Red' },
  ];

  const [selectedTaken, setSelectedTaken] = useState([]);
  const [filterTaken, setFilterTaken] = useState([]);

  const takenOptions = [
    { value: 'any', label: 'Any' },
    { value: 'taken', label: 'Taken' },
    { value: 'lost', label: 'Lost' },
  ];

  const minX = -120;
  const minY = -120;
  const maxX = 14970;
  const maxY = 14980;

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(0, 0, 0, 0);
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(23, 59, 59, 999);
    setEndDate(selectedDate);
  };

  const translateCoordinates = (x, y, mapSize) => {
    const newX = ((x - minX) / (maxX - minX)) * mapSize;
    const newY = ((maxY - y) / (maxY - minY)) * mapSize;
    return { newX, newY };
  };

  const generateObjectiveLabels = (matches) => {
    const objectiveCounters = {}; // To track counts of each objective type per gameId/scrimId
    return matches.map((match) => {
      const eventType = match.event.toLowerCase();
      const gameId = match.scrimId; // You can use scrimId or gameId as a unique identifier for each match

      if (!objectiveCounters[gameId]) {
        objectiveCounters[gameId] = {}; // Initialize a counter object for the game
      }

      if (!objectiveCounters[gameId][eventType]) {
        objectiveCounters[gameId][eventType] = 1; // Initialize counter for this specific objective in the game
      } else {
        objectiveCounters[gameId][eventType] += 1; // Increment the counter for this specific objective
      }

      return {
        ...match,
        eventLabel: `${match.event}${objectiveCounters[gameId][eventType]}`, // Append the count to the event
      };
    });
  };




  useEffect(() => {
    const updateMapWidth = () => {
      const calculatedWidth = (window.innerWidth - 17 - 200) / 4;
      setMapWidth(calculatedWidth);
    };

    updateMapWidth(); // Set the initial width

    window.addEventListener('resize', updateMapWidth);
    return () => window.removeEventListener('resize', updateMapWidth);
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Fetch data from the new API endpoint
        const response = await axiosInstance.get('objectives/', {
          params: {
            start: formatDate(startDate),
            end: formatDate(endDate),
            team: teamName,
          },
        });

        const data = response.data;

        // Extract and process patch options
        const uniquePatches = [...new Set(data.map((item) => item.event.patch))]
          .filter((patch) => patch)
          .sort((a, b) => {
            const [majorA, minorA] = a.split('.').map(Number);
            const [majorB, minorB] = b.split('.').map(Number);
            return majorA !== majorB ? majorA - majorB : minorA - minorB;
          });
        setPatchOption(uniquePatches.map((patch) => ({ value: patch, label: patch })));

        // Extract and process objective options
        const uniqueObjectives = [...new Set(data.map((item) => item.event.event))];
        setObjectiveOption(uniqueObjectives.map((obj) => ({ value: obj, label: obj })));

        // Transform match data for rendering
        const matches = data.map((item) => ({
          ...item.event,
          playerPositions: item.player_positions,
          owner: item.event.team === "100" ? item.event.team1 : item.event.team2,
          side: item.event.team1 === teamName ? "team1" : "team2",
        }));

        const labeledMatches = generateObjectiveLabels(matches);

        setMatchData(labeledMatches);
        setPlayerPositions(labeledMatches.flatMap((match) =>
          match.playerPositions.map((position) => ({
            ...position,
            scrimId: match.scrimId,
            eventTime: match.time,
          }))
        ));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, teamName]);


  const handlePatchChange = (selectedOptions) => {
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  const handleSideChange = (selectedOptions) => {
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterSide(leagueValues);
    setSelectedSide(selectedOptions);
  };

  const handleObjectiveChange = (selectedOptions) => {
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterObjective(leagueValues);
    setSelectedObjective(selectedOptions);
  };

  const handleTakenChange = (selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);
    setFilterTaken(values);
    setSelectedTaken(selectedOptions);
  };

  const filteredMatches = matchData.filter(match =>
    (!filterPatch.length || filterPatch.includes(match.patch)) &&
    (!filterObjective.length || filterObjective.includes(match.event)) &&
    (!filterSide.length || filterSide.includes(match.side)) &&
    (!filterTaken.length || filterTaken.includes(
      match.owner === teamName ? 'taken' : 'lost'
    ))
  );

  console.log(playerPositions)
  return (
    <div>
      <div className="filters" style={{marginBottom: '20px', color: 'white', display:'flex' }}>
        <div style={{ padding: '10px' }}>
          <CustomDateInput
            value={startDate?.toISOString().split('T')[0]}
            onChange={handleStartDateChange}
            style={{ marginLeft: '10px' }}
          />
        </div>
        -
        <div style={{ padding: '10px' }}>
          <CustomDateInput
            value={endDate?.toISOString().split('T')[0]}
            onChange={handleEndDateChange}
            style={{ marginLeft: '10px' }}
          />
        </div>
          Patch
          <div className='short-chooser'>
            <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
          </div>
          Side
          <div className='short-chooser'>
            <CustomSelect options={sideOption} selectedOption={selectedSide} onOptionChange={handleSideChange} />
          </div>
          Objective
          <div className='short-chooser'>
            <CustomSelect options={objectiveOption} selectedOption={selectedObjective} onOptionChange={handleObjectiveChange} />
          </div>
          Taken
          <div className="short-chooser">
            <CustomSelect
              options={takenOptions}
              selectedOption={selectedTaken}
              onOptionChange={handleTakenChange}
            />
          </div>

      </div>

      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', color: 'white' }}>
        {matchData.length > 0 && Object.keys(playerPositions).length > 0? (
          filteredMatches.map((match, index) => {
            return (
              <div
                key={index}
                className="map-container"
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '20px',
                }}
              >
                <img
                  src={mapPhotsDictionary[match.mapType || 'SummonersRift']}
                  alt="Map"
                  style={{
                    position: 'relative',
                    width: mapWidth,
                    height: mapWidth,
                    border: '2px solid white',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    left: `${(objectivesDict[match.event].x / 15000) * mapWidth}px`,
                    top: `${(objectivesDict[match.event].y / 15000) * mapWidth -3}px`,
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                    <img src={mapPhotsDictionary[match.event.toLowerCase()]} style={{width: '20px', height: '20px',}}/>
                </div>
                <div
                    style={{
                    position: 'absolute',
                    top:  0,
                    left: "20%",
                    color: 'blue',
                    fontWeight: teamName===match.team1 ? "bold" : "normal",

                    }}>
                    <img src={TeamIcons[match.team1]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                    {match.team1}
                </div>
                <div
                    style={{
                    position: 'absolute',
                    top:  0,
                    left: "45%",
                    color: 'white'
                    }}>
                    {Math.floor(match.time / 60)}:{String(match.time % 60).padStart(2, '0')}
                </div>
                <div
                    style={{
                    position: 'absolute',
                    top:  0,
                    left: "60%",
                    color: 'red',
                    fontWeight: teamName===match.team2 ? "bold" : "normal",

                    }}>
                    <img src={TeamIcons[match.team2]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                    {match.team2}
                </div>
                {playerPositions.filter((position) => position.scrimId === match.scrimId && position.time === match.time- 30)
                    .map((position, i) => {
                      const { newX, newY } = translateCoordinates(position.x, position.y, mapWidth);
                      return (
                        <div
                          key={`player-${i}`}
                          style={{
                            position: 'absolute',
                            top: `${newY - 13}px`,
                            left: `${newX - 13}px`,
                            width: '26px',
                            height: '26px',
                            backgroundColor:
                              position.side === (teamName === match.team1 ? 'blue' : 'red') ? 'purple' : 'transparent',
                            borderRadius: '50%',
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              top: '2px',
                              left: '2px',
                              width: '22px',
                              height: '22px',
                              backgroundColor: position.side,
                              borderRadius: '50%',
                            }}
                          >
                            <img
                              src={championPhotos[position.championName]}
                              alt=""
                              style={{
                                width: '20px',
                                height: '20px',
                                position: 'absolute',
                                borderRadius: '50%',
                                top: '1px',
                                left: '1px',
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                <div style={{width: "100%", display:'flex', justifyContent: 'space-between'}}>
                    <div>

                    {match.eventLabel}
                    </div>

                    <div>
                    {teamName} has {match.team === "100" ? (match.team1 === teamName ? "Taken" : "Lost") : (match.team2 === teamName ? "Taken" : "Lost")} objective
                    </div>
                </div>

              </div>
            );
          })
        ) : (
          <LoadingComponent />
        )}
      </div>
    </div>
  );
};

export default TeamObjectivesComponent;
