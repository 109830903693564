import React from 'react';
import './LoadingComponent.css';

const LoadingComponent = () => {
  return (
    <div className="loading-container">
      <div className="spinner"></div>
      <p style={{color: 'white'}}>Loading data, please wait...</p>
    </div>
  );
};

export default LoadingComponent;
