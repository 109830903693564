import React from 'react';
import Select from 'react-select';

const CustomSelect = ({ options, selectedOption, onOptionChange }) => {
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      width: '200px', // Set the desired width here
      zIndex: "200"
    }),
  };

  const customOption = ({ innerProps, label, value }) => (
    <div {...innerProps} className="custom-option">
      {label}
    </div>
  );

  return (
    <Select
      hideSelectedOptions={selectedOption.length < 3 ? true : false}
      isMulti
      options={options}
      onChange={(selectedOptions) => onOptionChange(selectedOptions)}
      value={selectedOption}
      components={{ Option: customOption }}
      styles={customStyles}
    />
  );
};

export default CustomSelect;
