import CHALLENGER from '../../rank_photos/challenger.png';
import GRANDMASTER from '../../rank_photos/grandmaster.png';
import MASTER from '../../rank_photos/master.webp';
import DIAMOND from '../../rank_photos/diamond.webp';
import EMERALD from '../../rank_photos/emerald.webp';

const rankIconDictionary = {
  CHALLENGER,
  GRANDMASTER,
  MASTER,
  DIAMOND,
  EMERALD
};

export default rankIconDictionary;