// src/utils/colorUtils.js

export const getRedCellColor = (value, maxValue) => {
  const normalizedValue = value / maxValue;
  const hue = 0; // Hue value (green)
  const saturation = 100; // Saturation value (100%)
  const minLightness = 100; // Minimum Lightness value (100%)
  const maxLightness = 50; // Maximum Lightness value (50%)

  const lightness = minLightness - normalizedValue * (minLightness - maxLightness);

  const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  return color;
};

export const getGreenCellColor = (value, maxValue) => {
    const normalizedValue = value / maxValue;
    const hue = 128; // Hue value (green)
    const saturation = 100; // Saturation value (100%)
    const minLightness = 100; // Minimum Lightness value (100%)
    const maxLightness = 50; // Maximum Lightness value (50%)

    const lightness = minLightness - normalizedValue * (minLightness - maxLightness);

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  export const getRedGreenCell = (value, minValue, maxValue) => {
    if (value === 0) {
      return `hsl(0, 0%, 100%)`; // White
    } else {
      const normalizedValue = value > 0 ? Math.abs(value) / maxValue : Math.abs(value / minValue);
      const hue = value > 0 ? 120 : 0; // Green for positive, Red for negative
      const lightness = 100 - 50 * normalizedValue;
      return `hsl(${hue}, 100%, ${lightness}%)`;
    }
  };

  export const getRedGreenCellno0 = (value, minValue, maxValue) => {
    const midpoint = (minValue + maxValue) / 2;
    const normalizedValue =
      value > midpoint
        ? (value - midpoint) / (maxValue - midpoint)
        : (-value + midpoint) / (minValue + midpoint);

    const hue = value > midpoint ? 120 : 0; // Green for positive, Red for negative
    const lightness = 100 - 50 * normalizedValue;

    return `hsl(${hue}, 100%, ${lightness}%)`;
  };
