import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { championPhotos, championData} from "./data/ChampionsIcon";
import roleIcon from './data/Role';
import { TeamIcons } from './data/TeamIcons';
import { Chart, registerables } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import runeIconDictionary from './data/RuneIcons';
import itemIconDictionary from './data/ItemIcons';
import mapPhotsDictionary from './data/MapData';
import summonerSpellDictionary from './data/SummonerSpellData';
import dragonDictionary from './data/DragonData';
import ProximityComponent from './Proximity';
import axiosInstance from './backend/axiosInstance';
import axiosInstanceMongo from './backend/axiosInstanceMongo';
import { handleROFLClick2 } from './base/base';

Chart.register(...registerables);

Chart.register({
    id: 'customLegend',
    afterUpdate: chart => {
      if (chart.config.type === 'doughnut') {
        chart.legend.options.display = false;
      }
    }
  });

  const TimelineComposite = ({ timelineData, chartData }) => {
    console.log(timelineData, chartData)
    const chartRef = useRef(null);
    const [eventPositions, setEventPositions] = useState([]);


    const lineChartData = {
      labels: chartData.map(item => item.time / 60), // Converting time to minutes
      datasets: [
        {
          label: 'Gold Difference Over Time',
          data: chartData.map(item => item.goldDiffrence),
          fill: true,
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: 'rgba(75,192,192,1)'
        }
      ]
    };

    // Chart options
    const lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true
        },
        x: {
          title: {
            display: true,
            text: 'Time (minutes)'
          }
        }
      },
      plugins: {
        legend: {
          display: false
        }
      },
      // Add a callback here to get the chart instance
    };

    useEffect(() => {
      const calculatePositions = () => {
        if (!chartRef.current) return;

        const chartInstance = chartRef.current; // Assuming chartRef.current is the chart instance
        if (!chartInstance) return;

        const newEventPositions = timelineData.map(event => {
          const xValue = event.time / 60; // Convert seconds to minutes
          const xPixel = chartInstance.scales.x.getPixelForValue(xValue);
          // Adjust Y positioning based on the team
          const yPixel = event.team === '100' ?
            chartInstance.scales.y.getPixelForValue(0) - 20 : // Place above the 0 line for team 100
            chartInstance.scales.y.getPixelForValue(0) + 10;  // Place below the 0 line for team 200

          return {
            x: xPixel,
            y: yPixel,
            iconSrc: mapPhotsDictionary[event.event],
            timeText: `${Math.floor(event.time / 60)}:${('0' + event.time % 60).slice(-2)}`,
            team: event.team
          };
        });

        setEventPositions(newEventPositions);
      };

      // Execute after a short delay to ensure the chart is fully rendered
      const timeoutId = setTimeout(calculatePositions, 150); // Adjust timeout as needed
      return () => clearTimeout(timeoutId);
    }, [chartData, timelineData]);


    return (
      <div className="timeline-composite">
        <div className="chart-container" style={{ position: 'relative' }}>
          <Line
            ref={chartRef}
            data={lineChartData}
            options={lineChartOptions}
          />
          {eventPositions.map((event, index) => (
            <div
              key={index}
              className={`event-annotation event-${event.team}`}
              style={{ position: 'absolute', left: `${event.x}px`, top: `${event.y}px` }}
            >
              <img src={event.iconSrc} alt={`Event at ${event.timeText}`} />
              <div style={{
                position: 'absolute',
                top: event.team === '100' ? '-20px' : '20px', // Adjust text position based on the team
                left: '50%',
                transform: 'translateX(-50%)',
                whiteSpace: 'nowrap',
                fontSize: "10px"
              }}>
                {event.timeText}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };


  const MyChart = ({ data }) => {
    const chartRef = useRef(null);
    const [timePoint1Minutes, setTimePoint1Minutes] = useState(0);
    const [timePoint1Seconds, setTimePoint1Seconds] = useState(1);
    const [timePoint2Minutes, setTimePoint2Minutes] = useState(10);
    const [timePoint2Seconds, setTimePoint2Seconds] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [dataType, setDataType] = useState('gold'); // State to track whether to show gold or damage

    const convertToSeconds = (minutes, seconds) => {
      return minutes * 60 + seconds;
    };

    const calculateDifferences = () => {

      if (!data || !data.timeline) {
        console.log(data)
        console.error("Data or timeline is undefined.");
        return;
      }

      const timeInSeconds1 = convertToSeconds(timePoint1Minutes, timePoint1Seconds);
      const timeInSeconds2 = convertToSeconds(timePoint2Minutes, timePoint2Seconds);
      const getPlayerStats = (timePoint, playerName) => {
        for (const team in data.timeline[timePoint]) {
          if (data.timeline[timePoint][team][playerName]) {
            const playerStats = data.timeline[timePoint][team][playerName];
            return {
              totalGold: parseInt(playerStats.totalGold || 0),
              totalDamage: parseInt(playerStats.totalDamageDealtChampions || 0),
            };
          }
        }
        return { totalGold: 0, totalDamage: 0 };
      };

      const playerNames = Object.keys(data.timeline[timeInSeconds1.toString()]['one']).concat(Object.keys(data.timeline[timeInSeconds2.toString()]['two']));
      const newChartData = playerNames.map(playerName => {
        const stats1 = getPlayerStats(timeInSeconds1, playerName);
        const stats2 = getPlayerStats(timeInSeconds2, playerName);

        return {
          playerName,
          goldDiff: stats2.totalGold - stats1.totalGold,
          dmgDiff: stats2.totalDamage - stats1.totalDamage
        };
      });

      setChartData(newChartData);
    };

    useEffect(() => {
      const myChart = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: chartData.map(player => player.playerName),
          datasets: [{
            label: dataType === 'gold' ? 'Gold Difference' : 'Damage Difference',
            data: chartData.map(player => dataType === 'gold' ? player.goldDiff : player.dmgDiff),
            backgroundColor: dataType === 'gold' ? 'gold' : 'red'
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });

      return () => {
        myChart.destroy();
      };
    }, [chartData, dataType]); // Dependency array includes dataType

    return (
      <>
        <div style={{ width: "100%", justifyContent: 'center' }}>
          <input style={{width: "40px"}} type="number" value={timePoint1Minutes} onChange={e => setTimePoint1Minutes(Math.max(0, parseInt(e.target.value, 10)))} />
          <label>:</label>
          <input style={{width: "40px"}} type="number" value={timePoint1Seconds} max="59" onChange={e => setTimePoint1Seconds(Math.min(59, Math.max(0, parseInt(e.target.value, 10))))} />

          <label> - </label>

          {/* Time Point 2 Inputs */}
          <input style={{width: "40px"}} type="number" value={timePoint2Minutes} onChange={e => setTimePoint2Minutes(Math.max(0, parseInt(e.target.value, 10)))} />
          <label>:</label>
          <input style={{width: "40px"}} type="number" value={timePoint2Seconds} max="59" onChange={e => setTimePoint2Seconds(Math.min(59, Math.max(0, parseInt(e.target.value, 10))))} />

          <button onClick={calculateDifferences}>Calculate Differences</button>
          <select onChange={e => setDataType(e.target.value)} value={dataType}>
            <option value="gold">Gold</option>
            <option value="damage">Damage</option>
          </select>
        </div>
        <canvas style={{maxHeight: "200px"}} ref={chartRef}></canvas>
      </>
    );
  };

  const MapWithWards = ({ wardsData, currentTime, mapImage, matchData, gameTimeline }) => {

    const mapBounds = {
      min: { x: -120, y: -120 },
      max: { x: 14870, y: 14980 },
    };

    const mapImageDimensions = {
      width: 512, // Adjust to the actual width of your map image
      height: 512, // Adjust to the actual height of your map image
    };

    // Calculate the scale factor to fit the image within the container
    const scale = Math.min(
      mapImageDimensions.width / (mapBounds.max.x - mapBounds.min.x),
      mapImageDimensions.height / (mapBounds.max.y - mapBounds.min.y)
    );

    // Calculate whether each ward should be visible based on placement and kill times
    const visibleWards = wardsData.filter((ward) => {
      return ward.placeTime <= currentTime && (!ward.killTime || ward.killTime >= currentTime);
    });

    const imageStyle = {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
    };


    const levelCircleStyle2 = {
      position: 'absolute',
      width: '15px', // Adjust the size of the level circle
      height: '15px', // Adjust the size of the level circle
      borderRadius: '50%', // Make the level circle circular
      backgroundColor: 'black', // Set the background color of the level circle to white
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };

    const playerBlockStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center', // Align items vertically center
      position: 'relative', // Relative because we want the respawn timer to be absolute relative to this
      height: '50%',
      width: "100%"
    };

    const respawnTimerStyle = {
      display: "flex",
      color: "red",
      fontSize: "20px",
      position: "absolute",
      top: "5px",
      fontWeight: "bold",
      left: '10px', // Adjust this value to position the respawn timer slightly to the left
    };

    const calculateTotalGold = (team) => {
      return Object.values(team).reduce((totalGold, player) => {
          // Ensure currentGold is a number before adding it to the total
          return totalGold + (player.totalGold);
      }, 0);
    }

    const calculateTotalStat = (team, stat) => {
      return Object.values(team).reduce((totalGold, player) => {
          // Ensure currentGold is a number before adding it to the total
        return totalGold + (parseInt(player[stat]));
      }, 0);
    }
    const itemsToShowLast = [3363, 3364, 3340, 3513];

    // Function to sort items, moving specific items to the end
    const sortItems = (items) => {
      return items.sort((a, b) => {
        const aIsSpecial = itemsToShowLast.includes(a);
        const bIsSpecial = itemsToShowLast.includes(b);

        if (aIsSpecial && !bIsSpecial) {
          return 1; // Move 'a' to the end
        } else if (!aIsSpecial && bIsSpecial) {
          return -1; // Keep 'a' before 'b'
        } else {
          return 0; // No change in order
        }
      });
    };

    const players = gameTimeline["timeline"][currentTime];
    return (
      <div style={{width:"100%", justifyContent: 'space-between'}}>
        <div style={{width:"100%", backgroundColor: 'white'}}>
          <div style={{width:"100%", textAlign: 'center'}}>
            {gameTimeline.team1} vs {gameTimeline.team2}
          </div>
          <div style={{width:"100%", textAlign: 'center', display:'flex',  justifyContent: 'space-evenly'}}>
            <div>
            {gameTimeline["timeline"][currentTime] ? calculateTotalStat(gameTimeline["timeline"][currentTime]["one"], "kills") : "0" }
            /
            {gameTimeline["timeline"][currentTime] ? calculateTotalStat(gameTimeline["timeline"][currentTime]["one"], "deaths") : "0"}
            /
            {gameTimeline["timeline"][currentTime] ? calculateTotalStat(gameTimeline["timeline"][currentTime]["one"], "assists") : "0"}
            </div>
            <div>
            {Math.floor(currentTime / 60)}:{String(currentTime % 60).padStart(2, '0')}
            </div>
            <div>
            {gameTimeline["timeline"][currentTime] ? calculateTotalStat(gameTimeline["timeline"][currentTime]["two"], "kills") : "0" }
            /
            {gameTimeline["timeline"][currentTime] ? calculateTotalStat(gameTimeline["timeline"][currentTime]["two"], "deaths"): "0" }
            /
            {gameTimeline["timeline"][currentTime] ? calculateTotalStat(gameTimeline["timeline"][currentTime]["two"], "assists") : "0"}
            </div>
          </div>
        </div>
        <div style={{display: 'flex', width:"100%", justifyContent: 'space-between'}}>
          <div style={{backgroundColor: '#e9e4f7', width: "35%", flexDirection: 'column'}}>
            <div style={{height: "15%", display:'flex', flexDirection: 'column', flexWrap: 'nowrap'}}>
              <div style={{width: "100%", display: "flex", justifyContent: 'space-evenly'}}>
                <div>
                    <img src={mapPhotsDictionary["BlueTurret"]} className='small-image' alt='tower'/>:
                    {gameTimeline && gameTimeline["turrets"]["red_turrets"] &&
                        Object.values(gameTimeline["turrets"]["red_turrets"]).reduce((acc, turret) => {
                          return acc + (turret["time_killed"] < currentTime && turret["time_killed"] != null ? 1 : 0);
                        }, 0)
                      }
                </div>
                <div>
                  <img src={mapPhotsDictionary["herald_blue"]}  className='small-image' alt='rift_herald'/>:
                  {gameTimeline && gameTimeline["objectives"]["one"]["riftHerald"] &&
                      gameTimeline["objectives"]["one"]["riftHerald"].reduce((acc, heraldTime) => {
                        return acc + (parseInt(heraldTime) < currentTime ? 1 : 0);
                      }, 0)
                    }
                </div>
                <div>
                    <img src={mapPhotsDictionary["baron_blue"]} className='small-image' alt='baron'/>:
                    {gameTimeline && gameTimeline["objectives"]["one"]["baron"] &&
                      gameTimeline["objectives"]["one"]["baron"].reduce((acc, heraldTime) => {
                        return acc + (parseInt(heraldTime) < currentTime ? 1 : 0);
                      }, 0)
                    }
                </div>
                <div>
                    Void:
                    {gameTimeline && gameTimeline["objectives"]["one"]["VoidGrub"] &&
                      gameTimeline["objectives"]["one"]["VoidGrub"].reduce((acc, heraldTime) => {
                        return acc + (parseInt(heraldTime) < currentTime ? 1 : 0);
                      }, 0)
                    }
                </div>
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: 'space-evenly'}}>
                <div style={{width: "100%", display: "flex", justifyContent: 'center'}}>
                  <img src={mapPhotsDictionary["dragon_blue"]} className='small-image' alt='baron'/>:
                  {gameTimeline && gameTimeline["objectives"]["one"]["dragon"] &&
                      gameTimeline["objectives"]["one"]["dragon"].map((dragon, index) => {
                          const key = Object.keys(dragon)[0]; // key is the time
                          const dragonType = dragon[key];

                          // Check if the dragon's time is greater than currentTime
                          if (parseInt(key) < currentTime) {
                              return (
                                  <div key={index}>
                                    <img src={dragonDictionary[dragonType]} style={{width: "20px"}}alt={dragonType+".png"} />
                                  </div>
                              );
                          } else {
                              return null; // or some other handling for dragons before currentTime
                          }
                      })
                  }
                </div>
                <div style={{width: "100%", display: "flex", justifyContent: 'center'}}>
                   <img src={mapPhotsDictionary["gold"]} style={{width: "30px", height: "30px"}} alt="gold" />:
                   {gameTimeline["timeline"][currentTime] ? calculateTotalGold(gameTimeline["timeline"][currentTime]["one"]) : "0"}
                   {" ("}
                   <div style={{color: (gameTimeline["timeline"][currentTime] ? calculateTotalGold(gameTimeline["timeline"][currentTime]["one"]) : "0") - (gameTimeline["timeline"][currentTime] ? calculateTotalGold(gameTimeline["timeline"][currentTime]["two"]) : "0") > 0 ? "green" : "red"}}>
                   {(gameTimeline["timeline"][currentTime] ? calculateTotalGold(gameTimeline["timeline"][currentTime]["one"]) : "0") - (gameTimeline["timeline"][currentTime] ? calculateTotalGold(gameTimeline["timeline"][currentTime]["two"]) : "0")}
                   </div>
                   {")"}
                </div>
              </div>
            </div>
            {/* Render players for the current time interval (if available) */
            players && players["one"] &&
            Object.keys(players["one"]).map((playerName, index) => {
              const player = players["one"][playerName]
              return (
                <div style={{flexDirection: 'row', height: '17%', justifyContent: "center"}}>
                  <div style={playerBlockStyle}>
                    <div style={{width: "30%", display: "flex"}}>
                      <img style={{ width: '40px',height: '40px',borderRadius: '50%', filter: player.Alive ? 'none' : 'grayscale(100%)' }} src={championPhotos[player.championName]} alt={player.championName} />
                      <div style={respawnTimerStyle}>
                        {player.respawnTimer ? player.respawnTimer : ""}
                      </div>
                      <div style={levelCircleStyle2}>
                        <span style={{color: "white", fontSize: "10px"}}>{player.level}</span>
                      </div>
                      <div style={{fontSize: "10px", justifyContent: "center", display: 'flex', flexDirection: 'column'}}>
                        {playerName}
                      </div>
                    </div>
                    <div style={{width: "50%"}}>
                    {
                      sortItems(player.items).map((item, index) => (
                        <React.Fragment key={index}>
                          <img src={itemIconDictionary["img" + item]} style={{width: "25px", height: "25px"}} alt={item}/>
                        </React.Fragment>
                      ))
                    }
                    </div>
                    <div style={{width: "20%"}}>
                      {"   "}
                      {player.kills}
                      {" / "}
                      {player.deaths}
                      {" / "}
                      {player.assists}
                      {"   "}
                      {player.CS}
                      ({(player.CS/(currentTime/60)).toFixed(2)})
                    </div>
                    <div style={{ width: "25px", backgroundColor: "black"}} >
                      <img src={summonerSpellDictionary[player.spell1_name]} style={{ height: "25px", filter:  player.spell1_cooldown === 0 ?  'none' : 'grayscale(100%)' }} alt={player.spell1_name}/>
                      <img src={summonerSpellDictionary[player.spell2_name]} style={{ height: "25px", filter:  player.spell2_cooldown === 0 ?  'none' : 'grayscale(100%)'}} alt={player.spell2_name}/>
                    </div>
                  </div>
                  <div style={{textAlign: "center", display: 'flex', justifyContent: 'space-evenly'}}>
                    <div>
                      <img src={mapPhotsDictionary["gold"]} alt="gold" style={{width: "30px", height: "30px"}}/>:
                      {player.totalGold}
                      ({player.currentGold})
                    </div>
                    <div>
                      DMG/M:
                      {(player.totalDamageDealtChampions/(currentTime/60)).toFixed(0)}
                    </div>
                  </div>
                </div>
              )
              })
            }
          </div>
          <div style={{ position: 'relative', width: `${mapImageDimensions.width}px`, height: `${mapImageDimensions.height}px` }}>
            <img src={mapImage} alt="MAPA" width={mapImageDimensions.width} height={mapImageDimensions.height} />

            {visibleWards.map((ward, index) => {
              // Calculate adjusted positions within map bounds and considering scale
              const wardBackgroundColor = ward.teamName === matchData[0].team2 ? 'red' : 'blue';
              const adjustedLeft = `${((( ward.x - mapBounds.min.x) * scale)-20).toFixed(2)}px`;
              const adjustedTop = `${(mapImageDimensions.height - ((ward.y - mapBounds.min.y) * scale)-20).toFixed(2)}px`;


              return (
                <div
                key={index}
                style={{
                  position: 'absolute',
                  left: adjustedLeft,
                  top: adjustedTop,
                  width: '40px', // Adjust size as needed
                  height: '40px', // Adjust size as needed
                  border: '2px solid ' + wardBackgroundColor, // Adjust border size and color
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000, // Ensure a higher z-index for wards
                }}
              >
                    <img src={mapPhotsDictionary["ward"]} alt='ward' style={{width: '10px', height: '10px', tintColor:wardBackgroundColor}} />
                  </div>
                  );
                })}
            {gameTimeline && gameTimeline["turrets"]["red_turrets"] &&
            Object.keys(gameTimeline["turrets"]["red_turrets"]).map((turret, index) => {
              const playerAdjustedLeft = `${(((gameTimeline["turrets"]["red_turrets"][index].position[0]- mapBounds.min.x) * scale) - 20).toFixed(2)}px`;
              const playerAdjustedTop = `${(mapImageDimensions.height - ((gameTimeline["turrets"]["red_turrets"][index].position[1] - mapBounds.min.y) * scale) - 20).toFixed(2)}px`;

              const playerStyle = {
                position: 'absolute',
                left: playerAdjustedLeft,
                top: playerAdjustedTop,
                zIndex: 1000, // Ensure a higher z-index for players,
              };

              return(
                <div key={index} style={playerStyle}>
                  <img style={{imageStyle, opacity: gameTimeline["turrets"]["red_turrets"][index]["time_killed"] < currentTime &&gameTimeline["turrets"]["red_turrets"][index]["time_killed"] != null  ? "0" : "1"}} src={mapPhotsDictionary["RedTurret"]} alt={"turret"} />
                </div>
              )
            })}
            {Object.entries(gameTimeline.jungle).map(([monsterType, positions]) =>
              Object.entries(positions).map(([positionKey, times], index) => {
                // Split the position string into x and y coordinates
                const [xPos, yPos] = positionKey.split(',').map(Number);

                // Calculate the adjusted left and top positions for CSS
                const monsterAdjustedLeft = `${(((xPos - mapBounds.min.x) * scale) - 10).toFixed(2)}px`;
                const monsterAdjustedTop = `${(mapImageDimensions.height - ((yPos - mapBounds.min.y) * scale) - 10).toFixed(2)}px`;

                // Determine visibility based on the current time
                const isVisible = times.some(([startTime, endTime]) => currentTime >= startTime && currentTime <= endTime);

                const monsterStyle = {
                  position: 'absolute',
                  left: monsterAdjustedLeft,
                  top: monsterAdjustedTop,
                  display: isVisible ? 'block' : 'none', // Use the visibility determination to set CSS
                  zIndex: 1000,
                };


                const circleStyle = {
                  width: '20px', // Circle diameter
                  height: '20px', // Circle diameter
                  backgroundColor: 'orange',
                  borderRadius: '50%', // Makes the div a circle
                };

                return isVisible ? (
                  <div key={`${monsterType}-${positionKey}-${index}`} style={monsterStyle}>
                    <div style={circleStyle}></div>
                  </div>
                ) : null;
              })
            )}

            {gameTimeline && gameTimeline["turrets"]["blue_turrets"] &&
            Object.keys(gameTimeline["turrets"]["blue_turrets"]).map((turret, index) => {
              const playerAdjustedLeft = `${(((gameTimeline["turrets"]["blue_turrets"][index].position[0]- mapBounds.min.x) * scale) - 20).toFixed(2)}px`;
              const playerAdjustedTop = `${(mapImageDimensions.height - ((gameTimeline["turrets"]["blue_turrets"][index].position[1] - mapBounds.min.y) * scale) - 20).toFixed(2)}px`;

              const playerStyle = {
                position: 'absolute',
                left: playerAdjustedLeft,
                top: playerAdjustedTop,
                zIndex: 1000, // Ensure a higher z-index for players,
              };

              return(
                <div key={index} style={playerStyle}>
                  <img style={{imageStyle, opacity: gameTimeline["turrets"]["blue_turrets"][index]["time_killed"] < currentTime &&gameTimeline["turrets"]["blue_turrets"][index]["time_killed"] != null  ? "0" : "1"}} src={mapPhotsDictionary["BlueTurret"]} alt={"turret"} />
                </div>
              )
            })}
            {players && players["one"] &&
            Object.keys(players["one"]).map((playerName, index) => {
              const player = players["one"][playerName];
              const playerAdjustedLeft = `${(((player.x - mapBounds.min.x) * scale)).toFixed(2)-20}px`;
              const playerAdjustedTop = `${(mapImageDimensions.height - ((player.y - mapBounds.min.y) * scale)-20).toFixed(2)}px`;


              const baseWidth = 3; // Constant width
              const blackBackgroundHeight = 40;
              const healthHeight = ((player.health/100)) * blackBackgroundHeight;
              const experienceHeight = ((player.exp/100)) * blackBackgroundHeight;

              let topRightCircleContent;
              if (player.level >= 6) {
                const rCooldownAsInt = parseInt(player.rCooldown, 10); // Convert rCooldown to an integer
                if (rCooldownAsInt === 0) {
                  // Display a green circle if level > 6 and rCooldown is 0
                  topRightCircleContent = (
                    <div style={{ width: '15px', height: '15px', borderRadius: '50%', backgroundColor: 'green' }}></div>
                  );
                } else {
                  // Display the rCooldown value if it's not 0
                  topRightCircleContent = (
                    <div style={{ width: '15px', height: '15px', borderRadius: '50%', backgroundColor: 'black', color: "white", fontSize: "10px" }}>
                      {rCooldownAsInt}
                    </div>
                  );
                }
              }

              const playerStyle = {
                position: 'absolute',
                left: playerAdjustedLeft,
                top: playerAdjustedTop,
                zIndex: 1000, // Ensure a higher z-index for players,
                filter: player.Alive ? 'none' : 'grayscale(100%)',
              };

              const blueFenceStyle = {
                width: '43px', // Adjust the width of the blue fence
                height: '43px', // Adjust the height of the blue fence
                borderRadius: '50%', // Make the blue fence circular
                backgroundColor: 'blue', // Set the background color of the blue fence to blue
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              };

              const levelCircleStyle = {
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '15px', // Adjust the size of the level circle
                height: '15px', // Adjust the size of the level circle
                borderRadius: '50%', // Make the level circle circular
                backgroundColor: 'black', // Set the background color of the level circle to white
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              };

              const healthBackgroundStyle = {
                position: 'absolute',
                left: `${baseWidth + 40}px`,
                width: `${baseWidth}px`, // Constant width
                height: `${blackBackgroundHeight}px`, // Constant height
                backgroundColor: 'black',
              };

              const healthBarStyle = {
                position: 'absolute',
                left: `${baseWidth + 40}px`, // Add 40 pixels to the right of the health bar
                width: `${baseWidth}px`, // Constant width
                height: `${healthHeight}px`, // Adjusted height based on health percentage
                backgroundColor: 'green',
                top: `${blackBackgroundHeight - healthHeight + 1}px`, // Position from the bottom
              };

              const ExperienceBackgroundStyle = {
                position: 'absolute',
                left: `${baseWidth + 43}px`,
                width: `${baseWidth}px`, // Constant width
                height: `${blackBackgroundHeight}px`, // Constant height
                backgroundColor: 'black',
              };

              const ExperienceStyle = {
                position: 'absolute',
                left: `${baseWidth + 43}px`, // Add 40 pixels to the right of the health bar
                width: `${baseWidth}px`, // Constant width
                height: `${experienceHeight}px`, // Adjusted height based on health percentage
                backgroundColor: 'purple',
                top: `${blackBackgroundHeight - experienceHeight + 1}px`, // Position from the bottom
              };

              return (
                <div key={index} style={playerStyle}>
                <div style={blueFenceStyle}>
                  <img style={imageStyle} src={championPhotos[player.championName]} alt={player.championName} />
                  <div style={levelCircleStyle}>
                    <span style={{color: "white", fontSize: "10px"}}>{player.level}</span>
                  </div>
                  <div style={{ position: 'absolute', top: 0, left: 0 }}>
                    {topRightCircleContent}
                  </div>
                  <div style={healthBackgroundStyle}></div>
                  <div style={healthBarStyle}></div>
                  <div style={ExperienceBackgroundStyle}></div>
                  <div style={ExperienceStyle}></div>
                </div>
              </div>
            );
            })}
            {players && players["two"] &&
            Object.keys(players["two"]).map((playerName, index) => {
              const player = players["two"][playerName];
              const playerAdjustedLeft = `${(((player.x - mapBounds.min.x) * scale)-20).toFixed(2)}px`;
              const playerAdjustedTop = `${(mapImageDimensions.height - ((player.y - mapBounds.min.y) * scale)-20).toFixed(2)}px`;


              const baseWidth = 3; // Constant width
              const blackBackgroundHeight = 40;
              const healthHeight = ((player.health/100)) * blackBackgroundHeight;
              const experienceHeight = ((player.exp/100)) * blackBackgroundHeight;

              let topRightCircleContent;
              if (player.level >= 6) {
                const rCooldownAsInt = parseInt(player.rCooldown, 10); // Convert rCooldown to an integer
                if (rCooldownAsInt === 0) {
                  // Display a green circle if level > 6 and rCooldown is 0
                  topRightCircleContent = (
                    <div style={{ width: '15px', height: '15px', borderRadius: '50%', backgroundColor: 'green' }}></div>
                  );
                } else {
                  // Display the rCooldown value if it's not 0
                  topRightCircleContent = (
                    <div style={{ width: '15px', height: '15px', borderRadius: '50%', backgroundColor: 'black', color: "white", fontSize: "10px" }}>
                      {rCooldownAsInt}
                    </div>
                  );
                }
              }

              const playerStyle = {
                position: 'absolute',
                left: playerAdjustedLeft,
                top: playerAdjustedTop,
                zIndex: 1000, // Ensure a higher z-index for players,
                filter: player.Alive ? 'none' : 'grayscale(100%)',
              };

              const blueFenceStyle = {
                width: '43px', // Adjust the width of the blue fence
                height: '43px', // Adjust the height of the blue fence
                borderRadius: '50%', // Make the blue fence circular
                backgroundColor: 'red', // Set the background color of the blue fence to blue
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              };

              const levelCircleStyle = {
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: '15px', // Adjust the size of the level circle
                height: '15px', // Adjust the size of the level circle
                borderRadius: '50%', // Make the level circle circular
                backgroundColor: 'black', // Set the background color of the level circle to white
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              };

              const healthBackgroundStyle = {
                position: 'absolute',
                left: `${baseWidth + 40}px`,
                width: `${baseWidth}px`, // Constant width
                height: `${blackBackgroundHeight}px`, // Constant height
                backgroundColor: 'black',
              };

              const healthBarStyle = {
                position: 'absolute',
                left: `${baseWidth + 40}px`, // Add 40 pixels to the right of the health bar
                width: `${baseWidth}px`, // Constant width
                height: `${healthHeight}px`, // Adjusted height based on health percentage
                backgroundColor: 'green',
                top: `${blackBackgroundHeight - healthHeight + 1}px`, // Position from the bottom
              };

              const ExperienceBackgroundStyle = {
                position: 'absolute',
                left: `${baseWidth + 43}px`,
                width: `${baseWidth}px`, // Constant width
                height: `${blackBackgroundHeight}px`, // Constant height
                backgroundColor: 'black',
              };

              const ExperienceStyle = {
                position: 'absolute',
                left: `${baseWidth + 43}px`, // Add 40 pixels to the right of the health bar
                width: `${baseWidth}px`, // Constant width
                height: `${experienceHeight}px`, // Adjusted height based on health percentage
                backgroundColor: 'purple',
                top: `${blackBackgroundHeight - experienceHeight + 1}px`, // Position from the bottom
              };

              return (
                <div key={index} style={playerStyle}>
                <div style={blueFenceStyle}>
                  <img style={imageStyle} src={championPhotos[player.championName]} alt={player.championName} />
                  <div style={levelCircleStyle}>
                    <span style={{color: "white", fontSize: "10px"}}>{player.level}</span>
                  </div>
                  <div style={{ position: 'absolute', top: 0, left: 0 }}>
                    {topRightCircleContent}
                  </div>
                  <div style={healthBackgroundStyle}></div>
                  <div style={healthBarStyle}></div>
                  <div style={ExperienceBackgroundStyle}></div>
                  <div style={ExperienceStyle}></div>
                </div>
              </div>
            );
            })}
          </div>
          <div style={{backgroundColor: '#f7c8c6', width: "35%", flexDirection: 'column'}}>
          <div style={{height: "15%", display:'flex', flexDirection: 'column', flexWrap: 'nowrap'}}>
              <div style={{width: "100%", display: "flex", justifyContent: 'space-evenly'}}>
                <div>
                    <img src={mapPhotsDictionary["RedTurret"]} className='small-image' alt='tower'/>:
                    {gameTimeline && gameTimeline["turrets"]["blue_turrets"] &&
                        Object.values(gameTimeline["turrets"]["blue_turrets"]).reduce((acc, turret) => {
                          return acc + (turret["time_killed"] < currentTime && turret["time_killed"] != null ? 1 : 0);
                        }, 0)
                      }
                </div>
                <div>
                  <img src={mapPhotsDictionary["herald_red"]}  className='small-image' alt='rift_herald'/>:
                  {gameTimeline && gameTimeline["objectives"]["two"]["riftHerald"] &&
                      gameTimeline["objectives"]["two"]["riftHerald"].reduce((acc, heraldTime) => {
                        return acc + (parseInt(heraldTime) < currentTime ? 1 : 0);
                      }, 0)
                    }
                </div>
                <div>
                    <img src={mapPhotsDictionary["baron_red"]} className='small-image' alt='baron'/>:
                    {gameTimeline && gameTimeline["objectives"]["two"]["baron"] &&
                      gameTimeline["objectives"]["two"]["baron"].reduce((acc, heraldTime) => {
                        return acc + (parseInt(heraldTime) < currentTime ? 1 : 0);
                      }, 0)
                    }
                </div>
                <div>
                    Void:
                    {gameTimeline && gameTimeline["objectives"]["two"]["VoidGrub"] &&
                      gameTimeline["objectives"]["two"]["VoidGrub"].reduce((acc, heraldTime) => {
                        return acc + (parseInt(heraldTime) < currentTime ? 1 : 0);
                      }, 0)
                    }
                </div>
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: 'space-evenly'}}>
                <div style={{width: "100%", display: "flex", justifyContent: 'center'}}>
                  <img src={mapPhotsDictionary["dragon_red"]} className='small-image' alt='baron'/>:
                  {gameTimeline && gameTimeline["objectives"]["two"]["dragon"] &&
                      gameTimeline["objectives"]["two"]["dragon"].map((dragon, index) => {
                          const key = Object.keys(dragon)[0]; // key is the time
                          const dragonType = dragon[key];

                          // Check if the dragon's time is greater than currentTime
                          if (parseInt(key) < currentTime) {
                              return (
                                  <div key={index}>
                                    <img src={dragonDictionary[dragonType]} style={{width: "20px"}}alt={dragonType+".png"} />
                                  </div>
                              );
                          } else {
                              return null; // or some other handling for dragons before currentTime
                          }
                      })
                  }
                </div>
                <div style={{width: "100%", display: "flex", justifyContent: 'center'}}>
                   <img src={mapPhotsDictionary["gold"]} alt="gold" style={{width: "30px", height: "30px"}} />:
                   {gameTimeline["timeline"][currentTime] ? calculateTotalGold(gameTimeline["timeline"][currentTime]["two"]) : "0"}
                   {" ("}
                   <div style={{color: (gameTimeline["timeline"][currentTime] ? calculateTotalGold(gameTimeline["timeline"][currentTime]["two"]) : "0") - (gameTimeline["timeline"][currentTime] ? calculateTotalGold(gameTimeline["timeline"][currentTime]["one"]) : "0") > 0 ? "green" : "red"}}>
                   {(gameTimeline["timeline"][currentTime] ? calculateTotalGold(gameTimeline["timeline"][currentTime]["two"]) : "0") - (gameTimeline["timeline"][currentTime] ? calculateTotalGold(gameTimeline["timeline"][currentTime]["one"]) : "0")}
                   </div>
                   {")"}
                </div>
              </div>
            </div>
            {/* Render players for the current time interval (if available) */
            players && players["two"] &&
            Object.keys(players["two"]).map((playerName, index) => {
              const player = players["two"][playerName]
              return (
                <div style={{flexDirection: 'row', height: '17%', justifyContent: "center"}}>
                  <div style={playerBlockStyle}>
                    <div style={{width: "30%", display: "flex"}}>
                      <img style={{ width: '40px',height: '40px',borderRadius: '50%', filter: player.Alive ? 'none' : 'grayscale(100%)' }} src={championPhotos[player.championName]} alt={player.championName} />
                      <div style={levelCircleStyle2}>
                        <span style={{color: "white", fontSize: "10px"}}>{player.level}</span>
                      </div>
                      <div style={respawnTimerStyle}>
                        {player.respawnTimer ? player.respawnTimer : ""}
                      </div>
                      <div style={{fontSize: "10px", justifyContent: "center", display: 'flex', flexDirection: 'column'}}>
                        {playerName}
                      </div>
                    </div>
                    <div style={{width: "50%"}}>
                      {
                        sortItems(player.items).map((item, index) => (
                          <React.Fragment key={index}>
                            <img src={itemIconDictionary["img" + item]} style={{width: "25px", height: "25px"}} alt={item}/>
                          </React.Fragment>
                        ))
                      }
                    </div>
                    <div style={{width: "20%"}}>
                      {"   "}
                      {player.kills}
                      {" / "}
                      {player.deaths}
                      {" / "}
                      {player.assists}
                      {"   "}
                      {player.CS}
                      ({(player.CS/(currentTime/60)).toFixed(2)})
                    </div>
                    <div style={{ width: "25px", backgroundColor: "black"}} >
                      <img src={summonerSpellDictionary[player.spell1_name]} style={{ height: "25px", filter:  player.spell1_cooldown === 0 ?  'none' : 'grayscale(100%)' }} alt={player.spell1_name}/>
                      <img src={summonerSpellDictionary[player.spell2_name]} style={{ height: "25px", filter:  player.spell2_cooldown === 0 ?  'none' : 'grayscale(100%)'}} alt={player.spell2_name}/>
                    </div>
                  </div>
                  <div style={{textAlign: "center", display: 'flex', justifyContent: 'space-evenly'}}>
                    <div>
                      <img src={mapPhotsDictionary["gold"]} alt="gold"  style={{width: "30px", height: "30px"}}/>:
                      {player.totalGold}
                      ({player.currentGold})
                    </div>
                    <div>
                      DMG/M:
                      {(player.totalDamageDealtChampions/(currentTime/60)).toFixed(0)}
                    </div>
                  </div>
                </div>

              )
              })
            }
          </div>
        </div>
      </div>
    );
  };




const ScrimComponent = () => {
  const XD = {labels: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
    datasets: [
      {
        label: 'Data 1',
        data: [5, 3, 4, 2, 5], // Sample data for each category
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
      },
    ],
  };
  const { gameId } = useParams();
  const [playerData, setPlayerData] = useState();
  const [team1Data, setTeam1Data] = useState();
  const [team2Data, setTeam2Data] = useState();
  const [matchData, setMatchData] = useState();
  const [wardData, setWardData] = useState();
  const [blueChampions, setBlueChampions] = useState([]);
  const [redChampions, setRedChampions] = useState([]);
  const [bluePlayers, setBluePlayers] = useState([]);
  const [redPlayers, setRedPlayers] = useState([]);
  const [chartData, setChartData] = useState([XD, XD, XD, XD]);
  const [expandedRows, setExpandedRows] = useState({});
  const [RedexpandedRows, setRedExpandedRows] = useState({});
  const [averageChampion, setAverageChampion] = useState([]);
  const [gameTimeline, setGameTimeline] = useState();

  const [Timeline, setTimeline] = useState([]);
  const [Events, setEvents] = useState([]);
  const [proximityData, setProximityData] = useState();

  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [playbackSpeed, setPlaybackSpeed] = useState(8.0);

  useEffect(() => {
    if (!matchData || !team1Data || !playerData){
    // Fetch data from the API and update the 'data' state with the response
    axiosInstance
      .get('parsedscrims/?gameId=' + gameId)
      .then((response) => {
        setMatchData(response.data);
        document.title = response.data[0]["team1"] + "-" + response.data[0]["team2"];
      })
      .catch((error) => {
        console.error('Error fetching match data:', error);
      });

      axiosInstance
      .get('timelineScrims/?gameId=' + gameId)
      .then((response) => {
        setTimeline(response.data);
      })
      .catch((error) => {
        console.error('Error fetching match data:', error);
      });

      axiosInstance
      .get('proximityScrims/?gameId=' + gameId)
      .then((response) => {
        setProximityData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching match data:', error);
      });

      axiosInstance
      .get('eventsScrims/?gameId=' + gameId)
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error('Error fetching match data:', error);
      });

      axiosInstance
      .get('playersScrims/?gameId=' + gameId)
      .then((response) => {
        setPlayerData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching player data:', error);
      });

      axiosInstance
      .get('performancesScrims/?gameId=' + gameId)
      .then((response) => {
        setTeam1Data(response.data.filter((team)=>team.side === "blue"))
        setTeam2Data(response.data.filter((team)=>team.side === "red"));
      })
      .catch((error) => {
        console.error('Error fetching team data:', error);
      });

      axiosInstance
      .get('averagematchup/?scrimId=' + gameId)
      .then((response) => {
        setAverageChampion(response.data);
      })
      .catch((error) => {
        console.error('Error fetching player data:', error);
      });

      axiosInstance
      .get('playerwards/?gameId=' + gameId)
      .then((response) => {
        setWardData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching player data:', error);
      });

    axiosInstanceMongo.get('games/' + gameId)
      .then((response) => {
        setGameTimeline(response.data);
      })
      .catch((error) => {
        console.error('Error fetching player data:', error);
      });

}}, [matchData, playerData, team1Data, team2Data, gameId]);

useEffect(() => {
  // Clear the interval when the component unmounts
  return () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };
}, [intervalId]);

  useEffect(() => {
    if (matchData && team1Data  && team2Data && playerData)
    {
    // Calculate blueChampions and redChampions
    const blueChampions = [
      "BB1",
      "BB2",
      "BB3",
      "BP1",
      "BP2",
      "BP3",
      "BB4",
      "BB5",
      "BP4",
      "BP5",
    ];
    const redChampions = [
      "RB1",
      "RB2",
      "RB3",
      "RP1",
      "RP2",
      "RP3",
      "RB4",
      "RB5",
      "RP4",
      "RP5",
    ];

    setBlueChampions(blueChampions);
    setRedChampions(redChampions);
    const bluePlayersToSet = playerData.filter((player)=>player.teamName===matchData[0].team1)
    const redPlayersToSet = playerData.filter((player)=>player.teamName===matchData[0].team2)
    setBluePlayers(bluePlayersToSet)
    setRedPlayers(redPlayersToSet)
    const dataForChartTeam1 = {
      labels: [bluePlayersToSet[0].summonerName, bluePlayersToSet[1].summonerName, bluePlayersToSet[2].summonerName, bluePlayersToSet[3].summonerName, bluePlayersToSet[4].summonerName],
      datasets: [
        {
          label: 'DMG%',
          data: [bluePlayersToSet[0].damageShared*100, bluePlayersToSet[1].damageShared*100, bluePlayersToSet[2].damageShared*100, bluePlayersToSet[3].damageShared*100, bluePlayersToSet[4].damageShared*100],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 2,
        },
        {
          label: 'G%',
          data: [bluePlayersToSet[0].goldShared*100, bluePlayersToSet[1].goldShared*100, bluePlayersToSet[2].goldShared*100, bluePlayersToSet[3].goldShared*100, bluePlayersToSet[4].goldShared*100],
          backgroundColor: 'rgba(140, 176, 150, 0.2)',
          borderColor: 'rgba(140, 176, 150,  1)',
          borderWidth: 2,
        },
      ],
    };
    const dataForChartTeam2 = {
      labels: [redPlayersToSet[0].summonerName, redPlayersToSet[1].summonerName, redPlayersToSet[2].summonerName, redPlayersToSet[3].summonerName, redPlayersToSet[4].summonerName],
      datasets: [
        {
          label: 'DMG%',
          data: [redPlayersToSet[0].damageShared*100, redPlayersToSet[1].damageShared*100, redPlayersToSet[2].damageShared*100, redPlayersToSet[3].damageShared*100, redPlayersToSet[4].damageShared*100],
          backgroundColor: 'rgba(240, 128, 128, 0.2)',
          borderColor: 'rgba(240, 128, 128, 1)',
          borderWidth: 2,
        },
        {
          label: 'G%',
          data: [redPlayersToSet[0].goldShared*100, redPlayersToSet[1].goldShared*100, redPlayersToSet[2].goldShared*100, redPlayersToSet[3].goldShared*100, redPlayersToSet[4].goldShared*100],
          backgroundColor: 'rgba(192, 75, 192, 0.2)',
          borderColor: 'rgba(192, 75, 192, 1)',
          borderWidth: 2,
        },
      ],
    };
    const dataForChartTeam1vsTeam2 = {
      labels: ['TOP', "JGL", "MID", "BOT", "SUP"],
      datasets: [
        {
          label: 'DMG% T1',
          data: [bluePlayersToSet[0].damageShared*100, bluePlayersToSet[1].damageShared*100, bluePlayersToSet[2].damageShared*100, bluePlayersToSet[3].damageShared*100, bluePlayersToSet[4].damageShared*100],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 2,
        },
        {
          label: 'DMG% T2',
          data: [redPlayersToSet[0].damageShared*100, redPlayersToSet[1].damageShared*100, redPlayersToSet[2].damageShared*100, redPlayersToSet[3].damageShared*100, redPlayersToSet[4].damageShared*100],
          backgroundColor: 'rgba(240, 128, 128, 0.2)',
          borderColor: 'rgba(240, 128, 128, 1)',
          borderWidth: 2,
        },
      ],
    };
    setChartData([dataForChartTeam1, dataForChartTeam2, dataForChartTeam1vsTeam2])
  }}, [matchData, team1Data, playerData, team2Data]);

    // Create a function to generate the chart configuration with a custom title
    const createBarChartConfig = (title) => {
      return {
        type: 'bar',
        options: {
          aspectRatio: false,
          indexAxis: 'y',
          elements: {
            bar: {
              borderWidth: 5,
            },
          },
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom', // Change to 'bottom' to move legend to the bottom
            },
            title: {
              display: true,
              text: title, // Use the custom title here
            },
          },
          scales: {
            y: {
              ticks: {
                font: {
                  size: 10, // Set the custom font size for data labels
                },
              },
            },
          },
        },
      };
    };

    // Create configurations for different bars with different titles
    const config1 = createBarChartConfig('Team 1 G% vs DMG%');
    const config2 = createBarChartConfig('DMG% T1 vs T2');
    const config3 = createBarChartConfig('Team 2 G% vs DMG%');


    console.log(matchData, team1Data, playerData)
  const teamPerformanceOrder = [
    "firstDragon",
    "firstHerald",
    "firstTower",
    "firstBlood",
    "firstVoidgrub",
    "voidGrubKilled",
    "dragonsBefore14",
    "towersBefore14",
    "TopTurretPlates",
    "MidTurretPlates",
    "BotTurretPlates",
    "DuoProximity",
    "JungleProximity",
  ]

  const handlePlayerClick = (teamName) => {
    window.location.href = `/player/${teamName}`;
  };


  const toggleExpandedRow = (index) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index],
    }));
  };

  const toggleRedExpandedRow = (index) => {
    setRedExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index],
    }));
  };

  const extendTimeline = (data) => {
    if (data.length === 0) return data;

    const lastEntry = data[data.length - 1];
    const newEntry = {
      id: lastEntry.id + 1,
      scrimId: lastEntry.scrimId,
      goldDiffrence: lastEntry.goldDiffrence,
      time: lastEntry.time + 60,
    };

    return [...data, newEntry];
  };

  const startTimer = () => {
    if (!isTimerRunning) {
      const intervalDuration = 1000 / playbackSpeed; // Adjust interval based on speed
      const newIntervalId = setInterval(() => {
        console.log(matchData[0]?.gameDuration);
        if (currentTime < matchData[0].gameDuration) {
          setCurrentTime((prevTime) => prevTime + 1);
        } else {
          stopTimer();
        }
      }, intervalDuration); // Use the calculated interval duration
      setIsTimerRunning(true);
      setIntervalId(newIntervalId);
    }
    setSliderValue(currentTime);
  };
  const stopTimer = () => {
    if (isTimerRunning) {
      clearInterval(intervalId);
      setIsTimerRunning(false);
    }
  };

  const toggleTimer = () => {
    if (isTimerRunning) {
      stopTimer();
    } else {
      startTimer();
    }
  };

  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setCurrentTime(newValue);
    setSliderValue(newValue);
  };

  function handleSpeedChange(event) {
    const newSpeed = parseFloat(event.target.value);
    setPlaybackSpeed(newSpeed);
  }

  const handleTeamClick = (teamName) => {
    window.location.href = `/team/${teamName}`;
  };

  const handleEditClick = (teamName) => {
    window.location.href = `/editscrim/${gameId}`;
  };




  console.log("timeline", gameTimeline)

  return (
    <div className='main-wrapper'>
      {matchData && team1Data && playerData ? (
        <>
          <div className='match-draft-container'>
          <table>
            <thead style={{ textAlign: 'center' }}>
              <tr>
                <th className="default-cell-img" style={{backgroundColor: matchData[0].winner === 100 ? 'rgb(144, 238, 144)' : 'rgb(255, 182, 193)'}}>{matchData[0].winner === 100 ? 1 : 0}</th>
                <th className="teamName" onClick={() => handleTeamClick(matchData[0].team1)}><img src={TeamIcons[matchData[0].team1]} alt='ni ma ikonki' className="small-image"/>{matchData[0].team1}</th>
                <th colSpan={2} className='default-doublecell-img'>{Math.floor(matchData[0].gameDuration / 60)}:{String(matchData[0].gameDuration % 60).padStart(2, '0')}</th>
                <th className="teamName" onClick={() => handleTeamClick(matchData[0].team2)}>{matchData[0].team2}<img src={TeamIcons[matchData[0].team2]} alt='ni ma ikonki' className="small-image"/></th>
                <th className="default-cell-img" style={{backgroundColor: matchData[0].winner === 200 ? 'rgb(144, 238, 144)' : 'rgb(255, 182, 193)'}}>{matchData[0].winner === 200 ? 1 : 0}</th>
              </tr>
            </thead>
        <tbody>
          {[...Array(10)].map((_, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "blue-cell-img" : "default-cell-img"}>
                        <img
                          src={championPhotos[championData[matchData[0][blueChampions[rowIndex]]]]}
                          alt={""}
                          className="small-image"
                        />
                      </td>
                      <td style={{borderBottom: rowIndex === 3 ? '1px solid black' : 'none', borderTop: rowIndex === 4  ? '1px solid black' : 'none'}}  colSpan={rowIndex === 0 || rowIndex === 1 || rowIndex === 2 || rowIndex === 6 || rowIndex === 7 ? 2 : 1} className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "blue-cell" : "default-cell"}>
                        {championData[matchData[0][blueChampions[rowIndex]]]}
                      </td>
                      {rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? (
                        <>
                        {rowIndex === 8 || rowIndex === 9 ? (
                          <>
                          <td className={"blue-cell-img"}>
                            <img src={roleIcon[matchData[0]['B'+(rowIndex-4)+'R']]} alt={1} className="small-image" />
                          </td>
                          <td className={"red-cell-img"}>
                            <img src={roleIcon[matchData[0]['R'+(rowIndex-4)+'R']]} alt={1} className="small-image" />
                          </td>
                          </>
                        ):(
                          <>
                            <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 ? "blue-cell-img" : "default-cell-img"}>
                              <img src={roleIcon[matchData[0]['B'+(rowIndex-2)+'R']]} alt={1} className="small-image" />
                            </td>
                            <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 ? "red-cell-img" : "default-cell-img"}>
                              <img src={roleIcon[matchData[0]['R'+(rowIndex-2)+'R']]} alt={1} className="small-image" />
                            </td>
                          </>
                        )
                      }
                        <td colSpan={1} style={{borderBottom: rowIndex === 4 || rowIndex === 8 ? '1px solid black' : 'none', borderTop: rowIndex === 5 || rowIndex === 9 ? '1px solid black' : 'none'}} className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell" : "default-cell"}>
                          {championData[matchData[0][redChampions[rowIndex]]]}
                        </td>
                        </>
                      ):(
                        <>
                        <td colSpan={2} className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell" : "default-cell"}>
                          {championData[matchData[0][redChampions[rowIndex]]]}
                        </td>
                        </>
                      )}
                      <td className={rowIndex === 3 || rowIndex === 4 || rowIndex === 5 || rowIndex === 8 || rowIndex === 9 ? "red-cell-img" : "default-cell-img"}>
                        <img
                          src={championPhotos[championData[matchData[0][redChampions[rowIndex]]]]}
                          alt={""}
                          className="small-image"
                        />
                      </td>
                    </tr>
          ))}
        </tbody>
      </table>
          <div style={{display: 'flex'}}>
            <button className='button-top-page' style={{width: "50%"}} onClick={() => handleROFLClick2(matchData[0].gameId, 1)}>ROFL</button> {/* Open ROFL link */}
          </div>
          </div>
          <div className='match-common-container'>
            <div className='header-match' style={{ textAlign: 'center' }} >
              <img src={TeamIcons[matchData[0].team1]} alt='ni ma ikonki' className="small-image"/>
              {matchData[0].team1} - {matchData[0].team2}
              <img src={TeamIcons[matchData[0].team2]} alt='ni ma ikonki' className="small-image"/>
            </div>
            <div className='match-stats'>
              <div className='match-blue-team'>
                {playerData.map(player => {
                  if (player.teamName === matchData[0].team1) {
                    return (
                      <div className='player-performance'>
                        <img src={championPhotos[player.championName]} alt={'s'} className="small-image"/>
                        <img className='small-image' src={runeIconDictionary["rune"+player.rune0]} alt=" " />
                        <div className="name" key={player.id} style={{ cursor: 'pointer' }} onClick={() => handlePlayerClick(player.summonerName)}>
                          {player.summonerName}
                        </div>
                        <div className="items">
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item0]} alt={"img"+player.item0}/>
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item1]} alt={"img"+player.item0}/>
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item2]} alt={"img"+player.item0}/>
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item3]} alt={"img"+player.item0}/>
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item4]} alt={"img"+player.item0}/>
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item5]} alt={"img"+player.item0}/>
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item6]} alt={"img"+player.item0}/>

                        </div>
                        <div className="stats">
                          {player.kills}/{player.deaths}/{player.assists}{"  "}{  player.CS}
                        </div>
                      </div>

                    );
                  }
                  return null; // Return null for players of other teams
                })}
              </div>
              <div className='match-red-team'>
                {playerData.map(player => {
                  if (player.teamName === matchData[0].team2) {
                    return (
                      <div className='player-performance'>
                        <img src={championPhotos[player.championName]} alt={'s'} className="small-image"/>
                        <img className='small-image' src={runeIconDictionary["rune"+player.rune0]} alt=" " />
                        <div className="name" key={player.id} style={{ cursor: 'pointer' }} onClick={() => handlePlayerClick(player.summonerName)}>
                          {player.summonerName}
                        </div>
                        <div className="items">
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item0]} alt={"img"+player.item0}/>
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item1]} alt={"img"+player.item0}/>
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item2]} alt={"img"+player.item0}/>
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item3]} alt={"img"+player.item0}/>
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item4]} alt={"img"+player.item0}/>
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item5]} alt={"img"+player.item0}/>
                          <img className='small-image-2' src={itemIconDictionary["img"+player.item6]} alt={"img"+player.item0}/>
                        </div>
                        <div className="stats">
                          {player.kills}/{player.deaths}/{player.assists}{" "}{player.CS}
                        </div>
                      </div>

                    );
                  }
                  return null; // Return null for players of other teams
                })}
              </div>
            </div>
            <div className='match-first-graphs'>
              <div style={{width: "33%", height: "100%"}}>
                <Bar data={chartData[0]} options={config1.options} />
              </div>
              <div style={{width: "33%", height: "100%"}}>
                <Bar data={chartData[2]} options={config2.options} />
              </div>
              <div style={{width: "33%", height: "100%"}}>
                <Bar data={chartData[1]} options={config3.options} />
              </div>
            </div>
          </div>
          <div className='match-teamperformance'>
            <div className='match-teamperformance-table' style={{width: "30%", height: "100%"}} >
              <table  className='inner-table' style={{ textAlign: 'center' }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'right' }}><img src={TeamIcons[matchData[0].team1]} alt='ni ma ikonki' className="small-image"/>{matchData[0].team1} </th>
                    <th>Stats</th>
                    <th>{matchData[0].team2} <img src={TeamIcons[matchData[0].team2]} alt='ni ma ikonki' className="small-image"/></th>
                  </tr>
                </thead>
                <tbody style={{color: "white"}}>
                {teamPerformanceOrder.map((category, index) => (
                    <tr key={index}>
                      <td>{category === 'JungleProximity' || category === 'DuoProximity'? `${(team1Data[0][category] * 100).toFixed(2)}%`: team1Data[0][category]}</td>
                      <td>{category}</td>
                      <td>{category === 'JungleProximity' || category === 'DuoProximity' ? `${(team2Data[0][category] * 100).toFixed(2)}%` : team2Data[0][category]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{color: "white", width: "70%", height: "100%"}}>
                <div style={{width: "100%", textAlign: 'center', color: "white"}}>
                    Timeline
                </div>
                <TimelineComposite timelineData={Events} chartData={extendTimeline(Timeline)} />
            </div>
          </div>
          <div className='match-playerperformance'>
              <table className='match-playerperformance-table' style={{ textAlign: 'center' }}>
                <thead>
                  <tr>
                    <th></th>
                    <th style={{ textAlign: 'left', width: "150px"}}>Name</th>
                    <th>K+A@8</th>
                    <th>D@8</th>
                    <th>GD@8</th>
                    <th>CSD@8</th>
                    <th>XPD@8</th>
                    <th>DMG/HP@8</th>
                    <th>K+A@14</th>
                    <th>D@14</th>
                    <th>GD@14</th>
                    <th>CSD@14</th>
                    <th>XPD@14</th>
                    <th>DMG %</th>
                    <th>G%</th>
                    <th>JGL PROX</th>
                    <th>DPM</th>
                    <th>GPM</th>
                    <th>CS/M</th>
                    <th></th>
                    {/* Add more header columns as needed */}
                  </tr>
                </thead>
                <tbody>
                  {bluePlayers.map((player, index) => {
                                        const currentmatchup = averageChampion.find(champion => champion.championName === player.championName);
                      return (
                        <React.Fragment key={index}>
                    <tr key={index}>
                      <img
                        src={championPhotos[player.championName]}
                        alt={""}
                        className="small-image"
                      />
                      <td style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => handlePlayerClick(player.summonerName)}>{player.summonerName}</td>
                      <td>{player.killsAndAssists8}</td>
                      <td>{player.deaths8}</td>
                      <td>{player.goldDiff8}</td>
                      <td>{player.csDiff8}</td>
                      <td>{player.expDiff8}</td>
                      <td>{(player.damageDealt8/player.damageReceived8).toFixed(1)}</td>
                      <td>{player.killsAndAssists14}</td>
                      <td>{player.deaths14}</td>
                      <td>{player.goldDiff14}</td>
                      <td>{player.csDiff14}</td>
                      <td>{player.expDiff14}</td>
                      <td>{(player.damageShared*100).toFixed(2)}%</td>
                      <td>{(player.goldShared*100).toFixed(2)}%</td>
                      <td>{(player.JungleProximity*100).toFixed(2)}%</td>
                      <td>{(player.DamageDealt/player.gameDuration).toFixed(0)}</td>
                      <td>{(player.GoldEarned/player.gameDuration).toFixed(0)}</td>
                      <td>{(player.CS/player.gameDuration).toFixed(2)}</td>
                      <td>
                        <button onClick={() => toggleExpandedRow(index)}>
                          {expandedRows[index] ? "↑" : "↓"}
                        </button>
                      </td>
                      {/* Add more data cells for other performance details */}
                    </tr>
                    {expandedRows[index] && (
                      <>
                          {currentmatchup !== undefined ? (
                          <>
                          <tr>
                            <td colSpan="2">AVG matchup</td>
                            <td>{currentmatchup.killsAndAssists8}</td>
                            <td>{currentmatchup.deaths8}</td>
                            <td>{(currentmatchup.goldDiff8).toFixed(0)}</td>
                            <td>{currentmatchup.csDiff8}</td>
                            <td>{(currentmatchup.expDiff8).toFixed(0)}</td>
                            <td>{(currentmatchup.damageRatio8).toFixed(2)}</td>
                            <td>{currentmatchup.killsAndAssists14}</td>
                            <td>{currentmatchup.deaths14}</td>
                            <td>{(currentmatchup.goldDiff14).toFixed(0)}</td>
                            <td>{currentmatchup.csDiff14}</td>
                            <td>{(currentmatchup.expDiff14).toFixed(0)}</td>
                            <td>{(currentmatchup.damageShared).toFixed(2)}%</td>
                            <td>{(currentmatchup.goldShared).toFixed(2)}%</td>
                            <td>{(currentmatchup.JungleProximity).toFixed(2)}%</td>
                            <td>{(currentmatchup.DamageDealt).toFixed(0)}</td>
                            <td>{(currentmatchup.GoldEarned).toFixed(0)}</td>
                            <td>{(currentmatchup.CS).toFixed(2)}</td>
                          </tr>
                        </>
                        ) : (
                        <>
                          <tr>
                            <td colSpan="18">No enough Data</td>
                          </tr>
                        </>
                        )}
                      </>
                    )}
                    </React.Fragment>
                  );
                  })}
                </tbody>
              </table>
              <br></br>
              <table className='match-playerperformance-table' style={{ textAlign: 'center' }}>
                <thead>
                  <tr>
                    <th></th>
                    <th style={{ textAlign: 'left', width: "150px"}}>Name</th>
                    <th>K+A@8</th>
                    <th>D@8</th>
                    <th>GD@8</th>
                    <th>CSD@8</th>
                    <th>XPD@8</th>
                    <th>DMG/HP@8</th>
                    <th>K+A@14</th>
                    <th>D@14</th>
                    <th>GD@14</th>
                    <th>CSD@14</th>
                    <th>XPD@14</th>
                    <th>DMG %</th>
                    <th>G%</th>
                    <th>JGL PROX</th>
                    <th>DPM</th>
                    <th>GPM</th>
                    <th>CS/M</th>
                    <th></th>
                    {/* Add more header columns as needed */}
                  </tr>
                </thead>
                <tbody>
                  {redPlayers.map((player, index) => {
                    const currentmatchup = averageChampion.find(champion => champion.championName === player.championName);
                    return (
                    <React.Fragment>
                      <tr key={index}>
                        <img
                          src={championPhotos[player.championName]}
                          alt={""}
                          className="small-image"
                        />
                        <td style={{ textAlign: 'left', cursor: 'pointer' }} onClick={() => handlePlayerClick(player.summonerName)}>{player.summonerName}</td>
                        <td>{player.killsAndAssists8}</td>
                        <td>{player.deaths8}</td>
                        <td>{player.goldDiff8}</td>
                        <td>{player.csDiff8}</td>
                        <td>{player.expDiff8}</td>
                        <td>{(player.damageDealt8/player.damageReceived8).toFixed(1)}</td>
                        <td>{player.killsAndAssists14}</td>
                        <td>{player.deaths14}</td>
                        <td>{player.goldDiff14}</td>
                        <td>{player.csDiff14}</td>
                        <td>{player.expDiff14}</td>
                        <td>{(player.damageShared*100).toFixed(2)}%</td>
                        <td>{(player.goldShared*100).toFixed(2)}%</td>
                        <td>{(player.JungleProximity*100).toFixed(2)}%</td>
                        <td>{(player.DamageDealt/player.gameDuration).toFixed(2)}</td>
                        <td>{(player.GoldEarned/player.gameDuration).toFixed(0)}</td>
                        <td>{(player.CS/player.gameDuration).toFixed(2)}</td>
                        <td>
                          <button onClick={() => toggleRedExpandedRow(index)}>
                            {RedexpandedRows[index] ? "↑" : "↓"}
                          </button>
                        </td>
                    </tr>
                    {RedexpandedRows[index] && (
                      <>
                          {currentmatchup !== undefined ? (
                          <>
                          <tr>
                            <td colSpan="2">AVG matchup</td>
                            <td>{currentmatchup.killsAndAssists8}</td>
                            <td>{currentmatchup.deaths8}</td>
                            <td>{(currentmatchup.goldDiff8).toFixed(0)}</td>
                            <td>{currentmatchup.csDiff8}</td>
                            <td>{(currentmatchup.expDiff8).toFixed(0)}</td>
                            <td>{(currentmatchup.damageRatio8).toFixed(2)}</td>
                            <td>{currentmatchup.killsAndAssists14}</td>
                            <td>{currentmatchup.deaths14}</td>
                            <td>{(currentmatchup.goldDiff14).toFixed(0)}</td>
                            <td>{currentmatchup.csDiff14}</td>
                            <td>{(currentmatchup.expDiff14).toFixed(0)}</td>
                            <td>{(currentmatchup.damageShared).toFixed(2)}%</td>
                            <td>{(currentmatchup.goldShared).toFixed(2)}%</td>
                            <td>{(currentmatchup.JungleProximity).toFixed(2)}%</td>
                            <td>{(currentmatchup.DamageDealt).toFixed(0)}</td>
                            <td>{(currentmatchup.GoldEarned).toFixed(0)}</td>
                            <td>{(currentmatchup.CS).toFixed(2)}</td>
                          </tr>
                        </>
                        ) : (
                        <>
                          <tr>
                            <td colSpan="18">No enough Data</td>
                          </tr>
                        </>
                        )}
                      </>
                    )}


                </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
          </div>
          <div className='match-wards' style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            {wardData && gameTimeline? (
              <>
              <MapWithWards currentTime={currentTime} wardsData={wardData} mapImage={mapPhotsDictionary["SummonersRift"]} matchData={matchData} gameTimeline={gameTimeline} />
                <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                  <button onClick={toggleTimer}>
                    {isTimerRunning ? "⏸️ Pause" : "▶ Play"}
                  </button>
                  <input
                    type="range"
                    min="0"
                    max={matchData[0].gameDuration}
                    value={sliderValue}
                    onChange={handleSliderChange}
                    style={{width: "40%"}}
                    />
                  <div style={{ color: "white" }}>
                    {Math.floor(currentTime / 60)}:{String(currentTime % 60).padStart(2, '0')}
                  </div>
                  <select onChange={handleSpeedChange} defaultValue="8.0">
                    <option value="1.0">1x</option>
                    <option value="4.0">4x</option>
                    <option value="8.0">8x</option>
                    <option value="16.0">16x</option>
                  </select>
                </div>
                <div style={{width: "100%", justifyContent: "center", color: 'white', textAlign: 'center'}}>
                  Charts Over time
                <MyChart data={gameTimeline}/>
            </div>
            </>
            ):(
              <>
              Loading...
              </>
            )}
          </div>
          {proximityData && proximityData.length === 10 && (
              <ProximityComponent proximityData={proximityData} />
          )}
          <div className='match-runes'>
            {bluePlayers.map((player, index) => (
              <div className='player-runes'>
                <div className='player-rune'><img className='small-image' src={championPhotos[player.championName]} alt=" " />{player.summonerName}  </div>
                <div className='player-rune'><img className='medium-image'src={runeIconDictionary["rune"+player.rune0]} alt=" " /> </div>
                <div className='player-rune'><img className='small-image'src={runeIconDictionary["rune"+player.rune1]} alt=" " /> <img className='small-image'src={itemIconDictionary["img"+0]} alt=" " /> </div>
                <div className='player-rune'><img className='small-image'src={runeIconDictionary["rune"+player.rune2]} alt=" " /><img className='small-image'src={runeIconDictionary["rune"+player.rune4]} alt=" " /> </div>
                <div className='player-rune'><img className='small-image'src={runeIconDictionary["rune"+player.rune3]} alt=" " /><img className='small-image'src={runeIconDictionary["rune"+player.rune5]} alt=" " /> </div>
              </div>
            ))}
            {redPlayers.map((player, index) => (
              <div className='player-runes'>
                <div className='player-rune'><img className='small-image' src={championPhotos[player.championName]} alt=" " />{player.summonerName}  </div>
                <div className='player-rune'><img className='medium-image'src={runeIconDictionary["rune"+player.rune0]} alt=" " /> </div>
                <div className='player-rune'><img className='small-image'src={runeIconDictionary["rune"+player.rune1]} alt=" " /> <img className='small-image'src={itemIconDictionary["img"+0]} alt=" " /> </div>
                <div className='player-rune'><img className='small-image'src={runeIconDictionary["rune"+player.rune2]} alt=" " /><img className='small-image'src={runeIconDictionary["rune"+player.rune4]} alt=" " /> </div>
                <div className='player-rune'><img className='small-image'src={runeIconDictionary["rune"+player.rune3]} alt=" " /><img className='small-image'src={runeIconDictionary["rune"+player.rune5]} alt=" " /> </div>

              </div>
            ))}
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}

            <button onClick={handleEditClick}>EDIT</button>
    </div>
  );

};

export default ScrimComponent;
