import React from 'react';
import './CustomDateInput.css'; // Add custom styling

const CustomDateInput = ({ value, onChange, placeholder }) => {
  return (
    <div className="custom-date-input__container">
      <input
        type="date"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="custom-date-input"
      />
    </div>
  );
};

export default CustomDateInput;
