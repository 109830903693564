import img096 from '../../item_photos/096.png';
import img1001 from '../../item_photos/1001.png';
import img1004 from '../../item_photos/1004.png';
import img1006 from '../../item_photos/1006.png';
import img1011 from '../../item_photos/1011.png';
import img1018 from '../../item_photos/1018.png';
import img1026 from '../../item_photos/1026.png';
import img1027 from '../../item_photos/1027.png';
import img1028 from '../../item_photos/1028.png';
import img1029 from '../../item_photos/1029.png';
import img1031 from '../../item_photos/1031.png';
import img1033 from '../../item_photos/1033.png';
import img1035 from '../../item_photos/1035.png';
import img1036 from '../../item_photos/1036.png';
import img1037 from '../../item_photos/1037.png';
import img1038 from '../../item_photos/1038.png';
import img1039 from '../../item_photos/1039.png';
import img1040 from '../../item_photos/1040.png';
import img1042 from '../../item_photos/1042.png';
import img1043 from '../../item_photos/1043.png';
import img1052 from '../../item_photos/1052.png';
import img1053 from '../../item_photos/1053.png';
import img1054 from '../../item_photos/1054.png';
import img1055 from '../../item_photos/1055.png';
import img1056 from '../../item_photos/1056.png';
import img1057 from '../../item_photos/1057.png';
import img1058 from '../../item_photos/1058.png';
import img1082 from '../../item_photos/1082.png';
import img1083 from '../../item_photos/1083.png';
import img1101 from '../../item_photos/1101.png';
import img1102 from '../../item_photos/1102.png';
import img1103 from '../../item_photos/1103.png';
import img1402 from '../../item_photos/1402.png';
import img1504 from '../../item_photos/1504.png';
import img1507 from '../../item_photos/1507.png';
import img1508 from '../../item_photos/1508.png';
import img1509 from '../../item_photos/1509.png';
import img1510 from '../../item_photos/1510.png';
import img1511 from '../../item_photos/1511.png';
import img1512 from '../../item_photos/1512.png';
import img2003 from '../../item_photos/2003.png';
import img2010 from '../../item_photos/2010.png';
import img2012 from '../../item_photos/2012.png';
import img2015 from '../../item_photos/2015.png';
import img2019 from '../../item_photos/2019.png';
import img2020 from '../../item_photos/2020.png';
import img2022 from '../../item_photos/2022.png';
import img2030 from '../../item_photos/2030.png';
import img2031 from '../../item_photos/2031.png';
import img2033 from '../../item_photos/2033.png';
import img2048 from '../../item_photos/2048.png';
import img2049 from '../../item_photos/2049.png';
import img2051 from '../../item_photos/2051.png';
import img2052 from '../../item_photos/2052.png';
import img2055 from '../../item_photos/2055.png';
import img2065 from '../../item_photos/2065.png';
import img2138 from '../../item_photos/2138.png';
import img2139 from '../../item_photos/2139.png';
import img2140 from '../../item_photos/2140.png';
import img2319 from '../../item_photos/2319.png';
import img2403 from '../../item_photos/2403.png';
import img2419 from '../../item_photos/2419.png';
import img2420 from '../../item_photos/2420.png';
import img2422 from '../../item_photos/2422.png';
import img2502 from '../../item_photos/2502.png';
import img2504 from '../../item_photos/2504.png';
import img3001 from '../../item_photos/3001.png';
import img3002 from '../../item_photos/3002.png';
import img3003 from '../../item_photos/3003.png';
import img3004 from '../../item_photos/3004.png';
import img3005 from '../../item_photos/3005.png';
import img3006 from '../../item_photos/3006.png';
import img3009 from '../../item_photos/3009.png';
import img3011 from '../../item_photos/3011.png';
import img3012 from '../../item_photos/3012.png';
import img3020 from '../../item_photos/3020.png';
import img3022 from '../../item_photos/3022.png';
import img3023 from '../../item_photos/3023.png';
import img3024 from '../../item_photos/3024.png';
import img3026 from '../../item_photos/3026.png';
import img3031 from '../../item_photos/3031.png';
import img3032 from '../../item_photos/3032.png';
import img3033 from '../../item_photos/3033.png';
import img3034 from '../../item_photos/3034.png';
import img3035 from '../../item_photos/3035.png';
import img3036 from '../../item_photos/3036.png';
import img3041 from '../../item_photos/3041.png';
import img3042 from '../../item_photos/3042.png';
import img3044 from '../../item_photos/3044.png';
import img3046 from '../../item_photos/3046.png';
import img3047 from '../../item_photos/3047.png';
import img3048 from '../../item_photos/3048.png';
import img3050 from '../../item_photos/3050.png';
import img3051 from '../../item_photos/3051.png';
import img3053 from '../../item_photos/3053.png';
import img3056 from '../../item_photos/3056.png';
import img3057 from '../../item_photos/3057.png';
import img3064 from '../../item_photos/3064.png';
import img3065 from '../../item_photos/3065.png';
import img3066 from '../../item_photos/3066.png';
import img3067 from '../../item_photos/3067.png';
import img3068 from '../../item_photos/3068.png';
import img3070 from '../../item_photos/3070.png';
import img3071 from '../../item_photos/3071.png';
import img3072 from '../../item_photos/3072.png';
import img3073 from '../../item_photos/3073.png';
import img3074 from '../../item_photos/3074.png';
import img3075 from '../../item_photos/3075.png';
import img3076 from '../../item_photos/3076.png';
import img3077 from '../../item_photos/3077.png';
import img3078 from '../../item_photos/3078.png';
import img3082 from '../../item_photos/3082.png';
import img3083 from '../../item_photos/3083.png';
import img3084 from '../../item_photos/3084.png';
import img3085 from '../../item_photos/3085.png';
import img3086 from '../../item_photos/3086.png';
import img3087 from '../../item_photos/3087.png';
import img3089 from '../../item_photos/3089.png';
import img3091 from '../../item_photos/3091.png';
import img3094 from '../../item_photos/3094.png';
import img3095 from '../../item_photos/3095.png';
import img3100 from '../../item_photos/3100.png';
import img3102 from '../../item_photos/3102.png';
import img3105 from '../../item_photos/3105.png';
import img3107 from '../../item_photos/3107.png';
import img3108 from '../../item_photos/3108.png';
import img3109 from '../../item_photos/3109.png';
import img3110 from '../../item_photos/3110.png';
import img3111 from '../../item_photos/3111.png';
import img3112 from '../../item_photos/3112.png';
import img3113 from '../../item_photos/3113.png';
import img3114 from '../../item_photos/3114.png';
import img3115 from '../../item_photos/3115.png';
import img3116 from '../../item_photos/3116.png';
import img3117 from '../../item_photos/3117.png';
import img3118 from '../../item_photos/3118.png';
import img3119 from '../../item_photos/3119.png';
import img3121 from '../../item_photos/3121.png';
import img3123 from '../../item_photos/3123.png';
import img3124 from '../../item_photos/3124.png';
import img3128 from '../../item_photos/3128.png';
import img3131 from '../../item_photos/3131.png';
import img3133 from '../../item_photos/3133.png';
import img3134 from '../../item_photos/3134.png';
import img3135 from '../../item_photos/3135.png';
import img3137 from '../../item_photos/3137.png';
import img3139 from '../../item_photos/3139.png';
import img3140 from '../../item_photos/3140.png';
import img3142 from '../../item_photos/3142.png';
import img3143 from '../../item_photos/3143.png';
import img3145 from '../../item_photos/3145.png';
import img3146 from '../../item_photos/3146.png';
import img3147 from '../../item_photos/3147.png';
import img3152 from '../../item_photos/3152.png';
import img3153 from '../../item_photos/3153.png';
import img3154 from '../../item_photos/3154.png';
import img3155 from '../../item_photos/3155.png';
import img3156 from '../../item_photos/3156.png';
import img3157 from '../../item_photos/3157.png';
import img3158 from '../../item_photos/3158.png';
import img3161 from '../../item_photos/3161.png';
import img3165 from '../../item_photos/3165.png';
import img3172 from '../../item_photos/3172.png';
import img3177 from '../../item_photos/3177.png';
import img3179 from '../../item_photos/3179.png';
import img3181 from '../../item_photos/3181.png';
import img3190 from '../../item_photos/3190.png';
import img2421 from '../../item_photos/2421.png';
import img3193 from '../../item_photos/3193.png';
import img3194 from '../../item_photos/3194.png';
import img3196 from '../../item_photos/3196.png';
import img3197 from '../../item_photos/3197.png';
import img3198 from '../../item_photos/3198.png';
import img3211 from '../../item_photos/3211.png';
import img3222 from '../../item_photos/3222.png';
import img3302 from '../../item_photos/3302.png';
import img3340 from '../../item_photos/3340.png';
import img3348 from '../../item_photos/3348.png';
import img3363 from '../../item_photos/3363.png';
import img3364 from '../../item_photos/3364.png';
import img3380 from '../../item_photos/3380.png';
import img3385 from '../../item_photos/3385.png';
import img3406 from '../../item_photos/3406.png';
import img3417 from '../../item_photos/3417.png';
import img3430 from '../../item_photos/3430.png';
import img3504 from '../../item_photos/3504.png';
import img3508 from '../../item_photos/3508.png';
import img3512 from '../../item_photos/3512.png';
import img3513 from '../../item_photos/3513.png';
import img3599 from '../../item_photos/3599.png';
import img3600 from '../../item_photos/3600.png';
import img3634 from '../../item_photos/3634.png';
import img3742 from '../../item_photos/3742.png';
import img3744 from '../../item_photos/3744.png';
import img3748 from '../../item_photos/3748.png';
import img3751 from '../../item_photos/3751.png';
import img3801 from '../../item_photos/3801.png';
import img3802 from '../../item_photos/3802.png';
import img3803 from '../../item_photos/3803.png';
import img3814 from '../../item_photos/3814.png';
import img3840 from '../../item_photos/3840.png';
import img3850 from '../../item_photos/3850.png';
import img3851 from '../../item_photos/3851.png';
import img3853 from '../../item_photos/3853.png';
import img3854 from '../../item_photos/3854.png';
import img3855 from '../../item_photos/3855.png';
import img3857 from '../../item_photos/3857.png';
import img3858 from '../../item_photos/3858.png';
import img3859 from '../../item_photos/3859.png';
import img3860 from '../../item_photos/3860.png';
import img3862 from '../../item_photos/3862.png';
import img3863 from '../../item_photos/3863.png';
import img3864 from '../../item_photos/3864.png';
import img3865 from '../../item_photos/3865.png';
import img3866 from '../../item_photos/3866.png';
import img3867 from '../../item_photos/3867.png';
import img3869 from '../../item_photos/3869.png';
import img3870 from '../../item_photos/3870.png';
import img3871 from '../../item_photos/3871.png';
import img3876 from '../../item_photos/3876.png';
import img3877 from '../../item_photos/3877.png';
import img3901 from '../../item_photos/3901.png';
import img3902 from '../../item_photos/3902.png';
import img3903 from '../../item_photos/3903.png';
import img3916 from '../../item_photos/3916.png';
import img4001 from '../../item_photos/4001.png';
import img4003 from '../../item_photos/4003.png';
import img4004 from '../../item_photos/4004.png';
import img4005 from '../../item_photos/4005.png';
import img4010 from '../../item_photos/4010.png';
import img4011 from '../../item_photos/4011.png';
import img4012 from '../../item_photos/4012.png';
import img4013 from '../../item_photos/4013.png';
import img4016 from '../../item_photos/4016.png';
import img4017 from '../../item_photos/4017.png';
import img4401 from '../../item_photos/4401.png';
import img4403 from '../../item_photos/4403.png';
import img4628 from '../../item_photos/4628.png';
import img4629 from '../../item_photos/4629.png';
import img4630 from '../../item_photos/4630.png';
import img4632 from '../../item_photos/4632.png';
import img4633 from '../../item_photos/4633.png';
import img4635 from '../../item_photos/4635.png';
import img4636 from '../../item_photos/4636.png';
import img4637 from '../../item_photos/4637.png';
import img4638 from '../../item_photos/4638.png';
import img4641 from '../../item_photos/4641.png';
import img4642 from '../../item_photos/4642.png';
import img4643 from '../../item_photos/4643.png';
import img4644 from '../../item_photos/4644.png';
import img4645 from '../../item_photos/4645.png';
import img4646 from '../../item_photos/4646.png';
import img6029 from '../../item_photos/6029.png';
import img6035 from '../../item_photos/6035.png';
import img6333 from '../../item_photos/6333.png';
import img6609 from '../../item_photos/6609.png';
import img6610 from '../../item_photos/6610.png';
import img6617 from '../../item_photos/6617.png';
import img6620 from '../../item_photos/6620.png';
import img6621 from '../../item_photos/6621.png';
import img6630 from '../../item_photos/6630.png';
import img6631 from '../../item_photos/6631.png';
import img6632 from '../../item_photos/6632.png';
import img6653 from '../../item_photos/6653.png';
import img6655 from '../../item_photos/6655.png';
import img6656 from '../../item_photos/6656.png';
import img6657 from '../../item_photos/6657.png';
import img6660 from '../../item_photos/6660.png';
import img6662 from '../../item_photos/6662.png';
import img6664 from '../../item_photos/6664.png';
import img6665 from '../../item_photos/6665.png';
import img6667 from '../../item_photos/6667.png';
import img6670 from '../../item_photos/6670.png';
import img6671 from '../../item_photos/6671.png';
import img6672 from '../../item_photos/6672.png';
import img6673 from '../../item_photos/6673.png';
import img6675 from '../../item_photos/6675.png';
import img6676 from '../../item_photos/6676.png';
import img6677 from '../../item_photos/6677.png';
import img6690 from '../../item_photos/6690.png';
import img6691 from '../../item_photos/6691.png';
import img6692 from '../../item_photos/6692.png';
import img6693 from '../../item_photos/6693.png';
import img6694 from '../../item_photos/6694.png';
import img6695 from '../../item_photos/6695.png';
import img6696 from '../../item_photos/6696.png';
import img6697 from '../../item_photos/6697.png';
import img6698 from '../../item_photos/6698.png';
import img6699 from '../../item_photos/6699.png';
import img6700 from '../../item_photos/6700.png';
import img6701 from '../../item_photos/6701.png';
import img7000 from '../../item_photos/7000.png';
import img7003 from '../../item_photos/7003.png';
import img8001 from '../../item_photos/8001.png';
import img8020 from '../../item_photos/8020.png';
import img2152 from '../../item_photos/2152.png';
import img2151 from '../../item_photos/2151.png';
import img2150 from '../../item_photos/2150.png';
import img0 from '../../item_photos/0.png';
import img2021 from '../../item_photos/2021.png';
import img3040 from '../../item_photos/3040.png';
import img2503 from '../../item_photos/2503.png';
import img2508 from '../../item_photos/2508.png';
import img3144 from '../../item_photos/3144.png';
import img6616 from '../../item_photos/6616.png';
import img7026 from '../../item_photos/7026.png';
import img7041 from '../../item_photos/7041.png';
import img7031 from '../../item_photos/7031.png';
import img7019 from '../../item_photos/7019.png';
import img7030 from '../../item_photos/7030.png';
import img7012 from '../../item_photos/7012.png';
import img7034 from '../../item_photos/7034.png';
import img7039 from '../../item_photos/7039.png';
import img7025 from '../../item_photos/7025.png';
import img7022 from '../../item_photos/7022.png';
import img3010 from '../../item_photos/3010.png';
import img3013 from '../../item_photos/3013.png';

const itemIconDictionary = {
  img3010,
  img3013,
  img7022,
  img7025,
  img7039,
  img7034,
  img7012,
  img7030,
  img7019,
  img7031,
  img7041,
  img7026,
  img6616,
  img3144,
  img2508,
  img2503,
  img3040: img3040,
  img0: img0,
  img096: img096,
  img1001: img1001,
  img1004: img1004,
  img1006: img1006,
  img1011: img1011,
  img1018: img1018,
  img1026: img1026,
  img1027: img1027,
  img1028: img1028,
  img1029: img1029,
  img1031: img1031,
  img1033: img1033,
  img1035: img1035,
  img1036: img1036,
  img1037: img1037,
  img1038: img1038,
  img1039: img1039,
  img1040: img1040,
  img1042: img1042,
  img1043: img1043,
  img1052: img1052,
  img1053: img1053,
  img1054: img1054,
  img1055: img1055,
  img1056: img1056,
  img1057: img1057,
  img1058: img1058,
  img1082: img1082,
  img1083: img1083,
  img1101: img1101,
  img1102: img1102,
  img1103: img1103,
  img1402: img1402,
  img1504: img1504,
  img1507: img1507,
  img1508: img1508,
  img1509: img1509,
  img1510: img1510,
  img1511: img1511,
  img1512: img1512,
  img2003: img2003,
  img2010: img2010,
  img2012: img2012,
  img2015: img2015,
  img2019: img2019,
  img2020: img2020,
  img2022: img2022,
  img2030: img2030,
  img2031: img2031,
  img2033: img2033,
  img2048: img2048,
  img2049: img2049,
  img2051: img2051,
  img2052: img2052,
  img2055: img2055,
  img2065: img2065,
  img2138: img2138,
  img2139: img2139,
  img2140: img2140,
  img2319: img2319,
  img2403: img2403,
  img2419: img2419,
  img2420: img2420,
  img2422: img2422,
  img2502: img2502,
  img2504: img2504,
  img2150: img2150,
  img2151: img2151,
  img2152: img2152,
  img3001: img3001,
  img3002: img3002,
  img3003: img3003,
  img3004: img3004,
  img3005: img3005,
  img3006: img3006,
  img3009: img3009,
  img3011: img3011,
  img3012: img3012,
  img3020: img3020,
  img3022: img3022,
  img3023: img3023,
  img3024: img3024,
  img3026: img3026,
  img3031: img3031,
  img3032: img3032,
  img3033: img3033,
  img3034: img3034,
  img3035: img3035,
  img3036: img3036,
  img3041: img3041,
  img3042: img3042,
  img3044: img3044,
  img3046: img3046,
  img3047: img3047,
  img3048: img3048,
  img3050: img3050,
  img3051: img3051,
  img3053: img3053,
  img3056: img3056,
  img3057: img3057,
  img3064: img3064,
  img3065: img3065,
  img3066: img3066,
  img3067: img3067,
  img3068: img3068,
  img3070: img3070,
  img3071: img3071,
  img3072: img3072,
  img3073: img3073,
  img3074: img3074,
  img3075: img3075,
  img3076: img3076,
  img3077: img3077,
  img3078: img3078,
  img3082: img3082,
  img3083: img3083,
  img3084: img3084,
  img3085: img3085,
  img3086: img3086,
  img3087: img3087,
  img3089: img3089,
  img3091: img3091,
  img3094: img3094,
  img3095: img3095,
  img3100: img3100,
  img3102: img3102,
  img3105: img3105,
  img3107: img3107,
  img3108: img3108,
  img3109: img3109,
  img3110: img3110,
  img3111: img3111,
  img3112: img3112,
  img3113: img3113,
  img3114: img3114,
  img3115: img3115,
  img3116: img3116,
  img3117: img3117,
  img3118: img3118,
  img3119: img3119,
  img3121: img3121,
  img3123: img3123,
  img3124: img3124,
  img3128: img3128,
  img3131: img3131,
  img3133: img3133,
  img3134: img3134,
  img3135: img3135,
  img3137: img3137,
  img3139: img3139,
  img3140: img3140,
  img3142: img3142,
  img3143: img3143,
  img3145: img3145,
  img3146: img3146,
  img3147: img3147,
  img3152: img3152,
  img3153: img3153,
  img3154: img3154,
  img3155: img3155,
  img3156: img3156,
  img3157: img3157,
  img3158: img3158,
  img3161: img3161,
  img3165: img3165,
  img3172: img3172,
  img3177: img3177,
  img3179: img3179,
  img3181: img3181,
  img3190: img3190,
  img2421: img2421,
  img3193: img3193,
  img3194: img3194,
  img3196: img3196,
  img3197: img3197,
  img3198: img3198,
  img3211: img3211,
  img3222: img3222,
  img3302: img3302,
  img3340: img3340,
  img3348: img3348,
  img3363: img3363,
  img3364: img3364,
  img3380: img3380,
  img3385: img3385,
  img3406: img3406,
  img3417: img3417,
  img3430: img3430,
  img3504: img3504,
  img3508: img3508,
  img3512: img3512,
  img3513: img3513,
  img3599: img3599,
  img3600: img3600,
  img3634: img3634,
  img3742: img3742,
  img3744: img3744,
  img3748: img3748,
  img3751: img3751,
  img3801: img3801,
  img3802: img3802,
  img3803: img3803,
  img3814: img3814,
  img3840: img3840,
  img3850: img3850,
  img3851: img3851,
  img3853: img3853,
  img3854: img3854,
  img3855: img3855,
  img3857: img3857,
  img3858: img3858,
  img3859: img3859,
  img3860: img3860,
  img3862: img3862,
  img3863: img3863,
  img3864: img3864,
  img3865: img3865,
  img3866: img3866,
  img3867: img3867,
  img3869: img3869,
  img3870: img3870,
  img3871: img3871,
  img3876: img3876,
  img3877: img3877,
  img3901: img3901,
  img3902: img3902,
  img3903: img3903,
  img3916: img3916,
  img4001: img4001,
  img4003: img4003,
  img4004: img4004,
  img4005: img4005,
  img4010: img4010,
  img4011: img4011,
  img4012: img4012,
  img4013: img4013,
  img4016: img4016,
  img4017: img4017,
  img4401: img4401,
  img4403: img4403,
  img4628: img4628,
  img4629: img4629,
  img4630: img4630,
  img4632: img4632,
  img4633: img4633,
  img4635: img4635,
  img4636: img4636,
  img4637: img4637,
  img4638: img4638,
  img4641: img4641,
  img4642: img4642,
  img4643: img4643,
  img4644: img4644,
  img4645: img4645,
  img4646: img4646,
  img6029: img6029,
  img6035: img6035,
  img6333: img6333,
  img6609: img6609,
  img6610: img6610,
  img6617: img6617,
  img6620: img6620,
  img6621: img6621,
  img6630: img6630,
  img6631: img6631,
  img6632: img6632,
  img6653: img6653,
  img6655: img6655,
  img6656: img6656,
  img6657: img6657,
  img6660: img6660,
  img6662: img6662,
  img6664: img6664,
  img6665: img6665,
  img6667: img6667,
  img6670: img6670,
  img6671: img6671,
  img6672: img6672,
  img6673: img6673,
  img6675: img6675,
  img6676: img6676,
  img6677: img6677,
  img6690: img6690,
  img6691: img6691,
  img6692: img6692,
  img6693: img6693,
  img6694: img6694,
  img6695: img6695,
  img6696: img6696,
  img6697: img6697,
  img6698: img6698,
  img6699: img6699,
  img6700: img6700,
  img6701: img6701,
  img7000: img7000,
  img7003: img7003,
  img8001: img8001,
  img8020: img8020,
  img2021: img2021,
};

export default itemIconDictionary;