import React, { useEffect, useState } from 'react';
import CustomSelect from './base/CustomSelect';
import { TeamIcons } from './data/TeamIcons';
import { Link } from 'react-router-dom';
import { useSeason } from './SeasonContext';
import { getGreenCellColor, getRedGreenCell, getRedGreenCellno0 } from './base/colorUtils';
import axiosInstance from './backend/axiosInstance';
const Teams = () => {
  const { currentSeasonTime } = useSeason();

  const [selectedLeague, setSelectedLeague] = useState([]);
  const [filterLeague, setFilterLeague] = useState([]);
  const [leagueOption, setLeagueOption] = useState([]);
  document.title = "Teams";
  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const [teamData, setTeamData] = useState([]);

  const [sortedColumn, setSortedColumn] = useState('teamName');
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance.get(`performancesOfficial/?start=${start}&end=${end}`)
      .then(response => {
        setTeamData(response.data);
        const uniqueLeagues = [...new Set(response.data.map((match) => match.leagueName))].sort();
        const formattedLeagueOptions = uniqueLeagues.map((patch) => ({
          value: patch,
          label: patch,
        }));
        const uniquePatches = [...new Set(response.data.map((match) => match.patch))]
        .filter(patch => patch !== null)
        .sort((a, b) => {
          const partsA = a.split('.').map(Number);
          const partsB = b.split('.').map(Number);

          if (partsA[0] !== partsB[0]) {
            return partsA[0] - partsB[0];
          }
          return partsA[1] - partsB[1];
        });
        const formattedPatchOptions = uniquePatches.map((patch) => ({
          value: patch,
          label: patch,
        }));
        setPatchOption(formattedPatchOptions);
        setLeagueOption(formattedLeagueOptions);
      })
      .catch(error => {
        console.error('Error fetching team data:', error);
      });
  }, [currentSeasonTime]);

  const getMaxValue = (property) => {
    return Math.max(...Object.values(teamAverages).map((team) => team[property]));
  };

  const getMinValue = (property) => {
    return Math.min(...Object.values(teamAverages).map((team) => team[property]));
  };

  const filteredTeamData = teamData.filter((team) => {
    const isLeagueGood = selectedLeague.length === 0 || filterLeague.includes(team.leagueName);
    const isPatchGood = filterPatch.length === 0 || filterPatch.includes(team.patch);
    return isLeagueGood && isPatchGood;
  });

  const teamAverages = filteredTeamData.reduce((acc, team) => {

    if (!acc[team.teamName]) {
      acc[team.teamName] = {
        teamName: team.teamName,
        GameDuration: 0,
        winner: 0,
        firstHerald: 0,
        firstDragon: 0,
        firstTower: 0,
        TopTurretPlates: 0,
        MidTurretPlates: 0,
        BotTurretPlates: 0,
        TeamGoldDiff8: 0,
        TeamCsDiff8: 0,
        TeamExpDiff8: 0,
        TeamGoldDiff14: 0,
        TeamCsDiff14: 0,
        TeamExpDiff14: 0,
        JungleProximity: 0,
        DuoProximity: 0,
        Records: 0,
        firstBlood:0,
        firstVoidgrub: 0,
        voidGrubKilled: 0,
        dragonsBefore14: 0,
        towersBefore14: 0,
        // Add other average metrics here
      };
    }
    acc[team.teamName].GameDuration += team.GameDuration;
    acc[team.teamName].winner += team.winner*100;
    acc[team.teamName].firstHerald += team.firstHerald*100;
    acc[team.teamName].firstTower += team.firstTower*100;
    acc[team.teamName].firstDragon += team.firstDragon*100;
    acc[team.teamName].TopTurretPlates += team.TopTurretPlates;
    acc[team.teamName].MidTurretPlates += team.MidTurretPlates;
    acc[team.teamName].BotTurretPlates += team.BotTurretPlates;
    acc[team.teamName].TeamGoldDiff8 += team.TeamGoldDiff8;
    acc[team.teamName].TeamCsDiff8 += team.TeamCsDiff8;
    acc[team.teamName].TeamExpDiff8 += team.TeamExpDiff8;
    acc[team.teamName].TeamGoldDiff14 += team.TeamGoldDiff14;
    acc[team.teamName].TeamCsDiff14 += team.TeamCsDiff14;
    acc[team.teamName].TeamExpDiff14 += team.TeamExpDiff14;
    acc[team.teamName].JungleProximity += team.JungleProximity*100;
    acc[team.teamName].DuoProximity += team.DuoProximity*100;
    acc[team.teamName].Records += 1;
    acc[team.teamName].firstBlood += team.firstBlood*100;
    acc[team.teamName].firstVoidgrub += team.firstVoidgrub*100;
    acc[team.teamName].voidGrubKilled += team.voidGrubKilled;
    acc[team.teamName].dragonsBefore14 += team.dragonsBefore14;
    acc[team.teamName].towersBefore14 += team.towersBefore14;
    return acc;
  }, {});

  Object.values(teamAverages).forEach(team => {

    // List of properties to round up to two decimal places
    const propertiesToRound = [
      'GameDuration',
      "winner",
      "firstHerald",
      "firstDragon",
      "firstTower",
      "TopTurretPlates",
      "MidTurretPlates",
      "BotTurretPlates",
      "TeamGoldDiff8",
      "TeamCsDiff8",
      "TeamExpDiff8",
      "TeamGoldDiff14",
      "TeamCsDiff14",
      "TeamExpDiff14",
      "JungleProximity",
      "DuoProximity",
      "firstBlood",
      "firstVoidgrub",
      "voidGrubKilled",
      "dragonsBefore14",
      "towersBefore14",
    ];

    // Loop through each property and round the value
    propertiesToRound.forEach(property => {
      team[property] /= team["Records"];
    });

  });

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(columnName);
      setSortDirection('asc');
    }
  };

  const compareValues = (key, order = 'asc') => {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // Property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  };

  function formatGameDuration(duration) {
    const minutes = Math.floor(duration);
    const seconds = Math.round((duration - minutes) * 60);

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  const sortedteamAverages = Object.values(teamAverages).sort(compareValues(sortedColumn, sortDirection));

  const handleLeagueChange = (selectedOptions) => {
    const leagueValues = selectedOptions.map(option => option.value);
    setFilterLeague(leagueValues)
    setSelectedLeague(selectedOptions); // Make sure to pass as an array
  };

    const handlePatchChange = (selectedOptions) => {
      const leagueValues = selectedOptions.map((option) => option.value);
      setFilterPatch(leagueValues);
      setSelectedPatch(selectedOptions);
    };

    const renderSortArrow = (column) => {
      if (sortedColumn !== column) return null;
      return sortDirection === 'asc' ? '↑' : '↓';
    };

    return (
        <div>
          <div className='filters'>
          League
            <div className='league-chooser'>
              <CustomSelect options={leagueOption} selectedOption={selectedLeague} onOptionChange={handleLeagueChange} />
            </div>
          Patch
            <div className='short-chooser'>
              <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
            </div>
          </div>
          <div style={{backgroundColor: 'white'}}>
            <div className='team-performance-table-container'>
              <table className='team-performance-table'>
                  <thead>
                    <tr style={{ cursor: 'pointer', textAlign: "center" }}>
                        <th onClick={() => handleSort('teamName')}>TeamName{renderSortArrow('teamName')}</th>
                        <th onClick={() => handleSort('GameDuration')}>Time{renderSortArrow('GameDuration')}</th>
                        <th onClick={() => handleSort('winner')}>WR%{renderSortArrow('winner')}</th>
                        <th onClick={() => handleSort('firstHerald')}>FirstHerald{renderSortArrow('firstHerald')}</th>
                        <th onClick={() => handleSort('firstDragon')}>FirstDragon{renderSortArrow('firstDragon')}</th>
                        <th onClick={() => handleSort('firstTower')}>FirstTower{renderSortArrow('firstTower')}</th>
                        <th onClick={() => handleSort('firstBlood')}>FirstBlood{renderSortArrow('firstBlood')}</th>
                        <th onClick={() => handleSort('firstVoidgrub')}>FirstVoidGrubs{renderSortArrow('firstVoidgrub')}</th>
                        <th onClick={() => handleSort('voidGrubKilled')}>VoidGrubKilled{renderSortArrow('voidGrubKilled')}</th>
                        <th onClick={() => handleSort('TopTurretPlates')}>TopPlates{renderSortArrow('TopTurretPlates')}</th>
                        <th onClick={() => handleSort('MidTurretPlates')}>MidPlates{renderSortArrow('MidTurretPlates')}</th>
                        <th onClick={() => handleSort('BotTurretPlates')}>BotPlates{renderSortArrow('BotTurretPlates')}</th>
                        <th onClick={() => handleSort('TeamGoldDiff8')}>GD@8{renderSortArrow('TeamGoldDiff8')}</th>
                        <th onClick={() => handleSort('TeamCsDiff8')}>CSD@8{renderSortArrow('TeamCsDiff8')}</th>
                        <th onClick={() => handleSort('TeamExpDiff8')}>XPD@8{renderSortArrow('TeamExpDiff8')}</th>
                        <th onClick={() => handleSort('TeamGoldDiff14')}>GD@14{renderSortArrow('TeamGoldDiff14')}</th>
                        <th onClick={() => handleSort('TeamCsDiff14')}>CSD@14{renderSortArrow('TeamCsDiff14')}</th>
                        <th onClick={() => handleSort('TeamExpDiff14')}>XPD@14{renderSortArrow('TeamExpDiff14')}</th>
                        <th onClick={() => handleSort('dragonsBefore14')}>Dragons@14{renderSortArrow('dragonsBefore14')}</th>
                        <th onClick={() => handleSort('towersBefore14')}>Towers@14{renderSortArrow('towersBefore14')}</th>
                        <th onClick={() => handleSort('JungleProximity')}>JGL%{renderSortArrow('JungleProximity')}</th>
                        <th onClick={() => handleSort('DuoProximity')}>Duo%{renderSortArrow('DuoProximity')}</th>
                    </tr>
                  </thead>
                  <tbody style={{textAlign: "center"}}>
                    {sortedteamAverages.map(team => (
                      <tr key={team.teamName}>
                        <td style={{ cursor: 'pointer', textAlign: 'left' }}>
                          <Link  to={`/team/${team.teamName}`} style={{textDecoration: "none", color: 'inherit', textAlign: 'left', alignItems: 'center', display: 'block'}}>
                            <img className="small-image" src={TeamIcons[team.teamName]} alt=""/>  {team.teamName}
                          </Link>
                        </td>
                        <td>{formatGameDuration(team.GameDuration.toFixed(2))}</td>
                        <td style={{ backgroundColor: getRedGreenCellno0(team.winner, 0, 100)}}>{team.winner.toFixed(2)}%</td>
                        <td style={{ backgroundColor: getGreenCellColor(team.firstHerald, getMaxValue('firstHerald')) }}>{team.firstHerald.toFixed(1)}%</td>
                        <td style={{ backgroundColor: getGreenCellColor(team.firstDragon, getMaxValue('firstDragon')) }}>{team.firstDragon.toFixed(1)}%</td>
                        <td style={{ backgroundColor: getGreenCellColor(team.firstTower, getMaxValue('firstTower')) }}>{team.firstTower.toFixed(1)}%</td>
                        <td style={{ backgroundColor: getGreenCellColor(team.firstBlood, getMaxValue('firstBlood')) }}>{team.firstBlood.toFixed(1)}%</td>
                        <td style={{ backgroundColor: getGreenCellColor(team.firstVoidgrub, getMaxValue('firstVoidgrub')) }}>{team.firstVoidgrub.toFixed(1)}%</td>
                        <td style={{ backgroundColor: getGreenCellColor(team.voidGrubKilled, getMaxValue('voidGrubKilled')) }}>{team.voidGrubKilled.toFixed(1)}</td>
                        <td style={{ backgroundColor: getGreenCellColor(team.TopTurretPlates, getMaxValue('TopTurretPlates')) }}>{team.TopTurretPlates.toFixed(2)}</td>
                        <td style={{ backgroundColor: getGreenCellColor(team.MidTurretPlates, getMaxValue('MidTurretPlates')) }}>{team.MidTurretPlates.toFixed(2)}</td>
                        <td style={{ backgroundColor: getGreenCellColor(team.BotTurretPlates, getMaxValue('BotTurretPlates')) }}>{team.BotTurretPlates.toFixed(2)}</td>
                        <td style={{ backgroundColor: getRedGreenCell(team.TeamGoldDiff8, getMinValue('TeamGoldDiff8'), getMaxValue('TeamGoldDiff8'))}}>{team.TeamGoldDiff8.toFixed(0)}</td>
                        <td style={{ backgroundColor: getRedGreenCell(team.TeamCsDiff8, getMinValue('TeamCsDiff8'), getMaxValue('TeamCsDiff8'))}}>{team.TeamCsDiff8.toFixed(0)}</td>
                        <td style={{ backgroundColor: getRedGreenCell(team.TeamExpDiff8, getMinValue('TeamExpDiff8'), getMaxValue('TeamExpDiff8'))}}>{team.TeamExpDiff8.toFixed(0)}</td>
                        <td style={{ backgroundColor: getRedGreenCell(team.TeamGoldDiff14, getMinValue('TeamGoldDiff14'), getMaxValue('TeamGoldDiff14'))}}>{team.TeamGoldDiff14.toFixed(0)}</td>
                        <td style={{ backgroundColor: getRedGreenCell(team.TeamCsDiff14, getMinValue('TeamCsDiff14'), getMaxValue('TeamCsDiff14'))}}>{team.TeamCsDiff14.toFixed(0)}</td>
                        <td style={{ backgroundColor: getRedGreenCell(team.TeamExpDiff14, getMinValue('TeamExpDiff14'), getMaxValue('TeamExpDiff14'))}}>{team.TeamExpDiff14.toFixed(0)}</td>
                        <td style={{ backgroundColor: getGreenCellColor(team.dragonsBefore14, getMaxValue('dragonsBefore14')) }}>{team.dragonsBefore14.toFixed(2)}</td>
                        <td style={{ backgroundColor: getGreenCellColor(team.towersBefore14, getMaxValue('towersBefore14')) }}>{team.towersBefore14.toFixed(2)}</td>
                        <td style={{ backgroundColor: getRedGreenCellno0(team.JungleProximity, getMinValue('JungleProximity'), getMaxValue('JungleProximity'))}}>{team.JungleProximity.toFixed(2)}%</td>
                        <td style={{ backgroundColor: getRedGreenCellno0(team.DuoProximity, getMinValue('DuoProximity'), getMaxValue('DuoProximity'))}}>{team.DuoProximity.toFixed(2)}%</td>
                      </tr>
                    ))}
                  </tbody>
              </table>
            </div>
          </div>
        </div>
    );
};

export default Teams;
