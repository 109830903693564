import React, { useEffect, useState } from 'react';
import { championPhotos } from "./data/ChampionsIcon";
import itemIconDictionary from './data/ItemIcons';
import axiosInstance from './backend/axiosInstance';

const SupportItemsComponent = () => {
    const [supportItemData, setSupportItemData] = useState([]);
    const [championAverages, setChampionAverages] = useState({});
    const [itemUpgradeCounts, setItemUpgradeCounts] = useState({});
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const itemIds = ["3869", "3870", "3871", "3876", "3877"];

    useEffect(() => {
        // Fetch data from the API and update the 'data' state with the response
        axiosInstance.get('scrims_support_items/?team='+localStorage.getItem("team"))
          .then(response => {
            setSupportItemData(response.data);
            calculateChampionAverages(response.data);
            calculateItemUpgrades(response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, [calculateItemUpgrades]);

    const calculateChampionAverages = (data) => {
        const championTimes = {};

        data.forEach(item => {
            if (!championTimes[item.championName]) {
                championTimes[item.championName] = { firstUpgradeTime: 0, secondUpgradeTime: 0, count: 0 };
            }
            championTimes[item.championName].firstUpgradeTime += parseInt(item.firstUpgradeTime);
            championTimes[item.championName].secondUpgradeTime += parseInt(item.secondUpgradeTime);
            championTimes[item.championName].count += 1;
        });

        const averages = {};
        Object.keys(championTimes).forEach(champion => {
            averages[champion] = {
                averageFirstUpgrade: (Math.floor(championTimes[champion].firstUpgradeTime / championTimes[champion].count)).toFixed(0),
                averageSecondUpgrade: (Math.floor(championTimes[champion].secondUpgradeTime / championTimes[champion].count)).toFixed(0)
            };
        });

        setChampionAverages(averages);
    };

    const handleSort = (field) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
        sortData(field);
    };

    const sortData = (field) => {
        const sortedData = [...supportItemData].sort((a, b) => {
            let valA = a[field];
            let valB = b[field];

            // If sorting by upgrade times, convert to numbers for proper comparison
            if (field === 'firstUpgradeTime' || field === 'secondUpgradeTime') {
                valA = parseInt(valA, 10);
                valB = parseInt(valB, 10);
            }

            if (valA < valB) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (valA > valB) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });

        setSupportItemData(sortedData);
    };

    const calculateItemUpgrades = (data) => {
        const upgradeCounts = {};

        data.forEach(item => {
            if (!upgradeCounts[item.championName]) {
                upgradeCounts[item.championName] = itemIds.reduce((acc, id) => ({ ...acc, [id]: 0 }), {});
            }
            if (itemIds.includes(item.selectedUpgrade)) {
                upgradeCounts[item.championName][item.selectedUpgrade] += 1;
            }
        });

        setItemUpgradeCounts(upgradeCounts);
    };

    const calculateSummonerAverages = (data) => {
        const summonerTimes = {};

        data.forEach(item => {
            if (!summonerTimes[item.summonerName]) {
                summonerTimes[item.summonerName] = { firstUpgradeTime: 0, secondUpgradeTime: 0, count: 0 };
            }
            summonerTimes[item.summonerName].firstUpgradeTime += parseInt(item.firstUpgradeTime);
            summonerTimes[item.summonerName].secondUpgradeTime += parseInt(item.secondUpgradeTime);
            summonerTimes[item.summonerName].count += 1;
        });

        const averages = {};
        Object.keys(summonerTimes).forEach(summoner => {
            averages[summoner] = {
                averageFirstUpgrade: (Math.floor(summonerTimes[summoner].firstUpgradeTime / summonerTimes[summoner].count)).toFixed(0),
                averageSecondUpgrade: (Math.floor(summonerTimes[summoner].secondUpgradeTime / summonerTimes[summoner].count)).toFixed(0)
            };
        });

        return averages;
    };

    const summonerAverages = calculateSummonerAverages(supportItemData);

    console.log(itemUpgradeCounts)
  return (
    <div  style={{backgroundColor: 'black', padding: "10px"}}>
        {supportItemData ? (
            <>
            <table style={{color: 'black', backgroundColor: "white", border: "4px solid rgb(255, 255, 255)"}}>
                <thead>
                <tr>
                    <th onClick={() => handleSort('summonerName')}>SummonerName</th>
                    <th onClick={() => handleSort('championName')}>ChampionName</th>
                    <th onClick={() => handleSort('enemyChampionName')}>EnemyChampionName</th>
                    <th onClick={() => handleSort('firstUpgradeTime')} style={{textAlign: "center"}}>1 Upgrade</th>
                    <th onClick={() => handleSort('secondUpgradeTime')} style={{textAlign: "center"}}>2 Upgrade</th>
                    <th style={{textAlign: "center"}}>Upgraded Item</th>
                </tr>
                </thead>
                <tbody>
                {supportItemData.map((record, index) => {
                    return (
                    <tr key={index}>
                        <td>{record.summonerName}</td>
                        <td><img className='small-image' src={championPhotos[record.championName]} alt="" />{record.championName}</td>
                        <td><img className='small-image' src={championPhotos[record.enemyChampionName]} alt="" /> {record.enemyChampionName}</td>
                        <td style={{textAlign: "center"}}>{Math.floor(record.firstUpgradeTime / 60)}:{String(record.firstUpgradeTime % 60).padStart(2, '0')}</td>
                        <td style={{textAlign: "center"}}>{Math.floor(record.secondUpgradeTime / 60)}:{String(record.secondUpgradeTime % 60).padStart(2, '0')}</td>
                        <td style={{textAlign: "center"}}><img className='small-image' src={itemIconDictionary["img"+record.selectedUpgrade]} alt={record.selectedUpgrade} /></td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
            <br></br>
            <br></br>
        <div style={{width: "100%", display: 'flex', justifyContent: 'space-around'}}>

            <table style={{color: 'black', backgroundColor: "white", border: "4px solid rgb(255, 255, 255)", width: "30%"}}>
                <thead>
                <tr>
                    <th>ChampionName</th>
                    <th style={{textAlign: "center"}}>1 Upgrade</th>
                    <th style={{textAlign: "center"}}>2 Upgrade</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(championAverages).map(([champion, averages], index) => (
                    <tr key={index}>
                        <td><img className='small-image' src={championPhotos[champion]} alt="" />{champion}</td>
                        <td style={{textAlign: "center"}}>{Math.floor(averages.averageFirstUpgrade  / 60)}:{String(averages.averageFirstUpgrade  % 60).padStart(2, '0')}</td>
                        <td style={{textAlign: "center"}}>{Math.floor(averages.averageSecondUpgrade / 60)}:{String(averages.averageSecondUpgrade % 60).padStart(2, '0')}</td>
                    </tr>
                    )
                    )}
                </tbody>
            </table>
            <table style={{color: 'black', backgroundColor: "white", border: "4px solid rgb(255, 255, 255)", width: "30%"}}>
                <thead>
                    <tr>
                        <th>Champion Name</th>
                        {itemIds.map(id => (
                            <th key={id} style={{textAlign: "center"}}>
                                <img className='small-image' src={itemIconDictionary["img"+id]} alt={id} />
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(itemUpgradeCounts).map(([champion, counts], index) => (
                        <tr key={index}>
                            <td><img className='small-image' src={championPhotos[champion]} alt="" />{champion}</td>
                            {itemIds.map(id => (
                                <td key={id} style={{textAlign: "center"}}> { counts[id] > 0 ?  counts[id]: "" }</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <table style={{color: 'black', backgroundColor: "white", border: "4px solid rgb(255, 255, 255)", width: "30%"}}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th style={{textAlign: "center"}}>1 Upgrade</th>
                        <th style={{textAlign: "center"}}>2 Upgrade</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(summonerAverages).map(([summoner, averages], index) => (
                        <tr key={index}>
                            <td>{summoner}</td>
                            <td style={{textAlign: "center"}}>{Math.floor(averages.averageFirstUpgrade / 60)}:{String(averages.averageFirstUpgrade % 60).padStart(2, '0')}</td>
                            <td style={{textAlign: "center"}}>{Math.floor(averages.averageSecondUpgrade / 60)}:{String(averages.averageSecondUpgrade % 60).padStart(2, '0')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
            </>
        ):(
            <>
                Loading...
            </>
        )}
    </div>
  );
};

export default SupportItemsComponent;
