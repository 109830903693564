import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import roleIcon from './data/Role';
import 'react-pivottable/pivottable.css';
import ChampionPoolComponnent from './ChampionPool';
import { useSeason } from './SeasonContext';
import CustomSelect from './base/CustomSelect';
import html2canvas from 'html2canvas';
import SoloqPageComponent from './SoloqPage';
import axiosInstance from './backend/axiosInstance';

const ScoutingComponent = () => {
  const { currentSeasonTime } = useSeason();

  const { teamName } = useParams();
  document.title = "Scouting " + teamName;

  const [playerData, setPlayerData] = useState([]);
  const [teamMatesData, setTeamMatesData] = useState(null);

  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);
  const [filteredPlayerChampionData, setFilteredPlayerChampionData] = useState([]);

  const [startDate, setStartDate] = useState(new Date('2024-05-14'));
  const [endDate, setEndDate] = useState(new Date());


  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());

    const fetchPlayerScrims = axiosInstance.get(`playersScrims/?team=${teamName}`);
    const fetchPlayers = axiosInstance.get(`players/?team=${teamName}&start=${start}&end=${end}`);
    const fetchTeamMates = axiosInstance.get(`teammates/?name=${teamName}&start=${start}&end=${end}`);

    Promise.all([fetchPlayerScrims, fetchPlayers, fetchTeamMates])
      .then(([playerScrimsResponse, playersResponse, teamMatesResponse]) => {
        const combinedPlayerData = [...playerScrimsResponse.data, ...playersResponse.data];
        const uniquePatches = [...new Set(combinedPlayerData.map((match) => match.patch))];
        const formattedPatchOptions = uniquePatches.sort().map((patch) => ({
          value: patch,
          label: patch,
        }));
        setPatchOption(formattedPatchOptions);
        setPlayerData(combinedPlayerData);
        setTeamMatesData(teamMatesResponse.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [teamName, currentSeasonTime]);

  useEffect(() => {
    const filteredPlayersPick = playerData && playerData.filter((player) => {
      const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
      return isPatchGood;
    });
    setFilteredPlayerChampionData(filteredPlayersPick || []);
  }, [playerData, filterPatch]);


  const captureScreenshot = (table) => {
    const element = document.getElementById(table);

    html2canvas(element)
      .then((canvas) => {
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.download = 'screenshot.png';
        link.href = image;
        link.click();
      })
      .catch(err => {
        console.error('Error capturing screenshot', err);
      });
  };

  const handlePatchChange = (selectedOptions) => {
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(0, 0, 0, 0); // Set hours to 00:00:00
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(23, 50, 59, 999); // Set hours to 23:59:59
    setEndDate(selectedDate);
  };

return (
  <div>
    <div className='filters'>
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>
      </div>
    <div  id="element1" style={{backgroundColor: "black"}} >
        <div style={{width:"100%", textAlign: "center", color: "white"}}>
        Scrims and Officials
        </div>
    <div style={{alignItems: "center"}}>
      {teamMatesData && (
        <div className="teammates-container">
          <div  style={{alignItems: "center", display: 'flex', justifyContent: 'center'}}  >
            {teamMatesData.map(teammate => (
              <a
              key={teammate.summonerName}
              href={`/player/${encodeURIComponent(teammate.summonerName)}`}
              style={{textDecoration: "none", color: "white"}}
              >
                <div className="teammate">
                  <img
                    src={roleIcon[teammate.Role]}
                    alt={`Role ${teammate.Role} Icon`}
                    className="role-icon"
                    />
                  <span className="teammate-name-2">{teammate.summonerName}</span>
                </div>
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
        <ChampionPoolComponnent playerData={filteredPlayerChampionData}/>
    </div>
    <button className="screenshot-button" onClick={() => captureScreenshot('element1')}>ScreenShot</button>
    <div className='filters'>
        Starting Date
        <input
          type="date"
          value={startDate?.toISOString().split('T')[0]}
          onChange={handleStartDateChange}
        />
        Ending Date
        <input
          type="date"
          value={endDate?.toISOString().split('T')[0]}
          onChange={handleEndDateChange}
        />
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>

      </div>
    {teamMatesData  && (
        <div className="teammates-container">
          <div style={{display: 'flex'}}  >
            {teamMatesData.map(teammate => (
              <SoloqPageComponent
                key={teammate.summonerName}
                name={teammate.summonerName}
                filterPatch={filterPatch}
                startDate={startDate}
                endDate={endDate}
              />
            ))}
          </div>
        </div>
      )}
  </div>
          );
        };

export default ScoutingComponent;
