import React from 'react';
import Select from 'react-select';
import './CustomInput.css'; // Add custom styling

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: '38px', // Matching select height
    minHeight: '38px',
    borderRadius: '4px',
  }),
};

const CustomInput = ({ value, onChange, placeholder }) => {
  return (
    <div className="react-select__input-container">
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="react-select__input"
      />
    </div>
  );
};

export default CustomInput;
