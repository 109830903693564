import React, { useState } from 'react';
import './Player.css';
import mapPhotsDictionary from './data/MapData';
import 'rc-slider/assets/index.css';
import html2canvas from 'html2canvas';

const WardMapComponent = ({ wardData, hoveredWard }) => {
    const mapWidth = 500;
    const mapHeight = 500;
    const minX = -120;
    const minY = -120;
    const maxX = 14970;
    const maxY = 14980;


    const captureScreenshot = (table) => {
        const element = document.getElementById(table);

        html2canvas(element)
            .then((canvas) => {
                const image = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.download = 'screenshot.png';
                link.href = image;
                link.click();
            })
            .catch(err => {
                console.error('Error capturing screenshot', err);
            });
    };

    const translateCoordinates = (x, y) => {
        const newX = ((x - minX) / (maxX - minX)) * mapWidth;
        const newY = ((maxY - y) / (maxY - minY)) * mapHeight;
        return { newX, newY };
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div id="heatmap-container-2" style={{ position: 'relative' }}>
                <img style={{ position: 'relative' }} src={mapPhotsDictionary["SummonersRift"]} alt="" />
                {wardData && wardData.map((position, index) => {
                    const isHovered = hoveredWard && hoveredWard === position; // Check if the current ward is the hovered one
                    const { newX, newY } = translateCoordinates(position.x, position.y);
                    const isNotHovered = !isHovered && hoveredWard !== null; // Identify if the ward is not hovered

                    return (
                        <div
                            key={index}
                            style={{
                                position: 'absolute',
                                top: `${newY - 30}px`, // Center the ring around the point
                                left: `${newX - 30}px`,
                                width: '60px', // Ring size
                                height: '60px',
                                borderRadius: '50%',
                                border: isHovered ? '3px solid yellow' : '2px solid rgba(255, 255, 255, 0.5)', // Change ring appearance on hover
                                backgroundColor: 'transparent',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: isHovered ? 9 : 0, // Ensure hovered ring stays on top
                                transition: 'all 0.3s ease',
                                opacity: isNotHovered ? 0.5 : 1, // Dim non-hovered elements
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: isHovered ? '8px' : '4px', // Increase size when hovered
                                    height: isHovered ? '8px' : '4px',
                                    backgroundColor: isHovered ? 'yellow' : position.side === 'blue' ? 'blue' : 'red', // Change color when hovered
                                    borderRadius: '50%',
                                    boxShadow: isHovered ? '0 0 10px 2px rgba(255, 255, 0, 0.8)' : 'none', // Add glowing effect on hover
                                    transition: 'all 0.3s ease',
                                }}
                            />
                            <div
                            style={{
                              position: 'absolute',
                              top: "25px",
                            }}>
                              {Math.floor(position.placeTime / 60)}:{String(position.placeTime % 60).padStart(2, '0')}
                            </div>

                        </div>
                    );
                })}
            </div>
            <button style={{ width: "100%" }} className='button-top-page' onClick={() => captureScreenshot('heatmap-container-2')}>ScreenShot</button>
        </div>
    );
};

export default WardMapComponent;
