import axios from 'axios';

const axiosInstanceMongo = axios.create({
  baseURL: 'https://sanchidataportal.com/api2/api/',
});
 // official/2729010/?apikey=1qDdBUiIXr6FYVMjnl
 axiosInstanceMongo.interceptors.request.use(
  (config) => {
    // Check if the URL already has a query string (e.g., if there are multiple parameters)
    if (config.url.includes('?')) {
      config.url += `/&apikey=1qDdBUiIXr6FYVMjnl`; // If there's already a ?, append with &
    } else {
      config.url += `/?apikey=1qDdBUiIXr6FYVMjnl`; // No ?, append with ?
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstanceMongo;
