import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://sanchidataportal.com/api/',
});

axiosInstance.interceptors.request.use(
  (config) => {
    const apiKey = localStorage.getItem('apikey');  // Get the API key dynamically, e.g., from local storage
    if (apiKey) {
      // Append the API key as a query parameter to the URL
      config.url += (config.url.includes('?') ? '&' : '?') + `apikey=${apiKey}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
