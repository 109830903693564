import React, { useMemo, useState } from 'react';
import { championPhotos, championData } from './data/ChampionsIcon';
import roleIcon from './data/Role'; // Assuming you have role icons mapped by name

const ChampionBlindCounterComponent = ({ filteredMatches = [], onChampionSelect  }) => {
  const [selectedChampions, setSelectedChampions] = useState({}); // State to track the selected champions and their categories

  const roleMap = {
    0: 'Top',
    1: 'Jungle',
    2: 'Mid',
    3: 'ADC',
    4: 'Support',
  };

  const roleData = useMemo(() => {
    const roles = Object.keys(roleMap).reduce((acc, key) => {
      acc[key] = { blind: {}, counter: {} };
      return acc;
    }, {});

    const enemies = Object.keys(roleMap).reduce((acc, role) => {
      acc[role] = {};
      return acc;
    }, {}); // Map to store enemies grouped by role

    filteredMatches.forEach((match) => {
      ['B', 'R'].forEach((sidePrefix) => {
        const opponentSide = sidePrefix === 'B' ? 'R' : 'B'; // Opposite side

        for (let i = 1; i <= 5; i++) {
          const roleIndex = match[`${sidePrefix}${i}R`];
          const pickType = match[`${sidePrefix}${i}BC`];
          const championId = match[`${sidePrefix}P${i}`];

          // Ensure role and champion structure exists in `enemies`
          if (!enemies[roleIndex][championId]) {
            enemies[roleIndex][championId] = { blind: {}, counter: {} };
          }

          // Find all enemy champions for the same role
          for (let j = 1; j <= 5; j++) {
            if (match[`${opponentSide}${j}R`] === roleIndex) {
              const enemyChampion = match[`${opponentSide}P${j}`];
              const enemyTarget = pickType === 'B' ? 'counter' : 'blind';

              // Ensure structure for enemy champion and increment count
              if (!enemies[roleIndex][championId][enemyTarget][enemyChampion]) {
                enemies[roleIndex][championId][enemyTarget][enemyChampion] = { count: 0 };
              }
              enemies[roleIndex][championId][enemyTarget][enemyChampion].count++;
            }
          }

          // Increment roles for blind/counter
          const target = pickType === 'B' ? 'blind' : 'counter';
          if (!roles[roleIndex][target][championId]) {
            roles[roleIndex][target][championId] = 0;
          }
          roles[roleIndex][target][championId]++;
        }
      });
    });

    return { roles, enemies };
  }, [filteredMatches]);

  const handleChampionClick = (championId, role, category) => {
    console.log(championId, role, category, -1)

    if (onChampionSelect) {
      console.log(championId, role, category, 0)

      onChampionSelect(championId, role, category);
    }
    setSelectedChampions((prev) => {
      const current = { ...prev };
      const key = `${championId}-${role}-${category}`;
      if (current[key]) {
        delete current[key];
      } else {
        current[key] = true;
      }
      return current;
    });

  };


  const renderTable = (data, title, role, category) => (
    <div style={{ width: '50%', textAlign: 'center' }}>
      <h3>{title}</h3>
      <table
        style={{
          width: '100%',
          textAlign: 'center',
          borderCollapse: 'collapse',
          marginBottom: '20px',
          color: 'white',
        }}
      >
        <thead>
          <tr>
            <th>Champion</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data)
            .sort((a, b) => b[1] - a[1])
            .map(([championId, count]) => {
              const key = `${championId}-${role}-${category}`;
              return (
                <React.Fragment key={championId}>
                  <tr
                    onClick={() => handleChampionClick(championId, role, category)}
                    style={{ cursor: 'pointer' }}
                  >
                    <td
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <img
                        src={championPhotos[championData[championId]] || 'fallback-image-url'}
                        alt={championId}
                        style={{ width: '20px', marginRight: '5px' }}
                      />
                      {championData[championId]}
                    </td>
                    <td>{count}</td>
                  </tr>
                  {selectedChampions[key] && (
                    <tr>
                      <td colSpan="2">
                        <table
                          style={{
                            width: '80%',
                            textAlign: 'center',
                            borderCollapse: 'collapse',
                            marginLeft: '30px',
                            color: 'white',
                          }}
                        >
                          <thead>
                            <tr>
                              <th>Enemy</th>
                              <th>C</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(
                              roleData.enemies[role]?.[championId]?.[category === 'blind' ? 'counter' : 'blind'] || {}
                            )
                              .sort((a, b) => b[1].count - a[1].count)
                              .map(([enemyChampionId, { count }]) => (
                                <tr key={enemyChampionId}>
                                  <td
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                    }}
                                  >
                                    <img
                                      src={
                                        championPhotos[championData[enemyChampionId]] ||
                                        'fallback-image-url'
                                      }
                                      alt={enemyChampionId}
                                      style={{ width: '20px', marginRight: '5px' }}
                                    />
                                    {championData[enemyChampionId]}
                                  </td>
                                  <td>{count}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
    </div>
  );

  return (
    <div style={{ color: 'white' }}>
      <div style={{ width: '100%', display: 'flex' }}>
        {Object.keys(roleMap).map((roleIndex) => {
          const roleName = roleMap[roleIndex];
          const { blind, counter } = roleData.roles[roleIndex];

          return (
            <div key={roleIndex} style={{ width: '20%', textAlign: 'center' }}>
              <h2>
                <img
                  src={roleIcon[roleIndex]}
                  alt={roleName}
                  style={{ width: '30px', marginRight: '10px', verticalAlign: 'middle' }}
                />
                {roleMap[roleIndex]}
              </h2>
              <div style={{ width: '100%', display: 'flex' }}>
                {renderTable(blind, 'Blind', roleIndex, 'blind')}
                {renderTable(counter, 'Counter', roleIndex, 'counter')}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChampionBlindCounterComponent;
