import React from 'react';
import iconDictionary from './data/Icons';
import { Link } from 'react-router-dom'

const OtherComponent = () => {

  const Components = [
    { title: 'Champions', url: '/champions', icon: "Champions"},
    { title: 'Scrims Champions Stats', url: '/scrimchampionstats', icon: "zebatka"},
    { title: 'Presence By Patch', url: '/pickbanbypatch', icon: "champion"},
    { title: 'Team Champion Pool Comparison', url: '/champpoolcomparison', icon: "zebatka"},


  ];

  return (
    <div style={{width: "100%", height: "100%", padding: "20px", display:'flex', flexWrap: 'wrap', alignContent: "space-between", justifyContent: "space-around" }}>
        {Components.map((item, index) => (
            <Link to={item.url} style={{width: "30%", height: "400px", padding: '5px', border: "4px solid rgb(255, 255, 255)", borderRadius: "10px", justifyContent: "center", alignItems:"center", display: "flex", flexWrap: "wrap"}}>
              <img style={{width:"50%", height:"50%"}} src={iconDictionary[item.icon]} alt='XD'/>
              <div style={{width: "100%", textAlign: "center", alignItems:"center", justifyContent: "center", color: "white"}}>
                {item.title}
              </div>
            </Link>
        ))}
    </div>
  );
};

export default OtherComponent;