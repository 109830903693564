import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import Sidebar from './Sidebar';
import HomePage from './components/HomePage';
import './App.css'; // Import the CSS file for styling
import CalendarPage from './components/Calendar';
import TierlistForm from './components/Tierlist';
import Players from './components/Players';
import Teams from './components/Teams';
import MatchHistoryComponent from './components/MatchHistory';
import ScrimHistoryComponent from './components/ScrimHistory';
import MatchComponent from './components/Match';
import ScrimComponent from './components/Scrim';
import DraftsComponent from './components/TeamDraft';
import ChampionComponent from './components/Champion';
import ChampionsComponent from './components/Champions';
import OtherComponent from './components/Other';
import SupportItemsComponent from './components/SupportItems';
import ScrimsChampionComponent from './components/ScrimsChampionStats';
import PickBanByPatchComponent from './components/PickBanByPatch';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MapComponent from './components/Map';
import MapScrimComponent from './components/MapScrim';
import Cookies from 'js-cookie';
import { SeasonProvider } from './components/SeasonContext';
import EditableTable from './components/Draft';
import EditScrimComponent from './components/EditScrim';
import LeaderboardComponent from './components/Leaderboard';
import ChampionRoleComponent from './components/ChampionRole';
import ChampPoolComponent from './components/ChampPoolComparison';
import backgroundImage from './background.jpg';
import MasterTeamComponent from './components/MasterTeam';
import MasterPlayerComponent from './components/MasterPlayer';
import PlayerSoloqPageComponentF from './components/PlayerSoloQPageF';
import MasterMatchupFinderComponent from './components/MasterMatchupFinder';
function App() {
  const [loggedIn, setLoggedIn] = useState(Cookies.get('loggedIn') === 'true');
  useEffect(() => {
    if (!loggedIn) {
      Cookies.remove('loggedIn');
      Cookies.remove('security');
      Cookies.remove('team');
    }
  }, [loggedIn]);

  return (
    <GoogleOAuthProvider clientId="35952612290-cq2dp4s7b5cfqtceka6icg505lml9jlu.apps.googleusercontent.com">
      <SeasonProvider>
        <Router>
          <AppContent loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
        </Router>
      </SeasonProvider>
    </GoogleOAuthProvider>
  );
}

function AppContent({ loggedIn, setLoggedIn }) {
  return (
    <div className="app-container">
      {<Sidebar />}
      <div className="content" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
        <Routes>
          <Route path="/map/:gameId" element={<MapComponent />} />
          {loggedIn ? (
            <>
              <Route exact path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage setLoggedIn={setLoggedIn} />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/calendar" element={<CalendarPage />} />

              <Route path="/players" element={<Players />} />
              <Route path="/teams" element={<Teams />} />
              <Route path="/champions" element={<ChampionsComponent />} />
              <Route path="/championRole" element={<ChampionRoleComponent />} />
              <Route path="/matchhistory" element={<MatchHistoryComponent />} />
              <Route path="/scrimhistory" element={<ScrimHistoryComponent />} />
              <Route path="/match/:gameId" element={<MatchComponent />} />
              <Route path="/scrim/:gameId" element={<ScrimComponent />} />
              <Route path="/champions/:champion" element={<ChampionComponent />} />
              <Route path="/teamsDrafts" element={<DraftsComponent />} />
              <Route path="/credits" element={<TierlistForm />} />
              <Route path="/other" element={<OtherComponent />} />
              <Route path="/supportitems" element={<SupportItemsComponent />} />
              <Route path="/scrimchampionstats" element={<ScrimsChampionComponent />} />
              <Route path="/pickbanbypatch" element={<PickBanByPatchComponent />} />
              <Route path="/map/:gameId" element={<MapComponent />} />
              <Route path="/mapScrim/:gameId" element={<MapScrimComponent />} />
              <Route path="/editScrim/:gameId" element={<EditScrimComponent />} />
              <Route path="/tierlist" element={<TierlistForm />} />
              <Route path="/draft" element={<EditableTable />} />
              <Route path="/leaderboard" element={<LeaderboardComponent />} />
              <Route path="/champpoolcomparison" element={<ChampPoolComponent />} />
              <Route path="/drafts" element={<EditableTable />} />
              <Route path="/matchupfinder" element={<MasterMatchupFinderComponent />} />

              <Route path="/team/:teamName" element={<MasterTeamComponent />} />
              <Route path="/player/:name" element={<MasterPlayerComponent />} />
              <Route path="/soloQ/:name" element={<PlayerSoloqPageComponentF />} />


            </>
          ) : (
            <>
              <Route path="*" element={<LoginPage setLoggedIn={setLoggedIn} />} />
            </>
          )}
        </Routes>
      </div>
    </div>
  );
}

export default App;
