import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TeamIcons } from './data/TeamIcons';
import CustomSelect from './base/CustomSelect';
import { Link } from 'react-router-dom';
import ChampionPoolComponnent from './ChampionPool';
import { useSeason } from './SeasonContext';
import LoadingComponent from './base/LoadingComponent';
import { getGreenCellColor, getRedGreenCell, getRedGreenCellno0 } from './base/colorUtils';
import './Team.css'
import axiosInstance from './backend/axiosInstance';
const TeamComponent = () => {
    const { currentSeasonTime } = useSeason();

    const { teamName } = useParams();
    document.title = teamName;
    const [selectedPatch, setSelectedPatch] = useState([]);
    const [filterPatch, setFilterPatch] = useState([]);
    const [patchOption, setPatchOption] = useState([]);

    const [playerData, setPlayerData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [filterTeamData, setFilterTeamData] = useState([]);

    const [filteredPlayerChampionData, setFilteredPlayerChampionData] = useState([]);

    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance.get(`players/?team=${teamName}&start=${start}&end=${end}`)
      .then((response) => {
        setPlayerData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

      axiosInstance
      .get('performances/?team='+teamName+ '&start=' + start + "&end=" + end)
      .then((response) => {
        const sortedData = response.data.slice().sort((a, b) => b.patch.localeCompare(a.patch));
        const uniquePatches = [...new Set(response.data.map((match) => match.patch))];
        const formattedPatchOptions = uniquePatches.sort().map((patch) => ({
          value: patch,
          label: patch,
        }));
        setPatchOption(formattedPatchOptions);
        setTeamData(sortedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [currentSeasonTime, teamName]);

  useEffect(() => {
    const filteredPlayersPick = playerData.filter((player) => {
      const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
      return isPatchGood;
    });
    setFilteredPlayerChampionData(filteredPlayersPick)

    const filteredTeamData = teamData.filter((record) => {
      const isPatchGood = filterPatch.length === 0 || filterPatch.includes(record.patch);
      return isPatchGood;
    });

    setFilterTeamData(filteredTeamData)

  }, [playerData, filterPatch, teamData]);

  const getMaxValue = (property) => {
    return Math.max(...Object.values(filterTeamData).map((player) => player[property]));
  };

  const getMinValue = (property) => {
    return Math.min(...Object.values(filterTeamData).map((player) => player[property]));
  };


  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      // If the same column is clicked, reverse the sort direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // If a different column is clicked, set it as the sorted column and default to ascending order
      setSortedColumn(columnName);
      setSortDirection('asc');
    }
  };

  const compareValues = (key, order = 'asc') => {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // Property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  };

  function averageValue(columnName) {
    const total = filterTeamData.reduce((acc, record) => acc + record[columnName], 0);
    return (total / filterTeamData.length); // You can adjust the number of decimal places
  };

  const handlePatchChange = (selectedOptions) => {
    //setMatchesForCurrentPage([]);
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  function formatGameDuration(duration) {
    const minutes = Math.floor(duration);
    const seconds = Math.round((duration - minutes) * 60);

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleMatchClick = (gameId) => {
    window.location.href = `/match/${gameId}`;
  };

  return (
    <div>
      <div className='filters'>
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>
      </div>
      <div style={{backgroundColor: 'white'}}>
      {filterTeamData ? (
        <div className='team-performance-table-container'>
          <table className='team-performance-table'>
            <thead>
              <tr>
                <th onClick={() => handleSort('EnemyTeamName')}>Enemy Team</th>
                <th onClick={() => handleSort('side')} aria-label="Sort by side">Side</th>
                <th onClick={() => handleSort('GameDuration')}>Time</th>
                <th onClick={() => handleSort('winner')}>Winner</th>
                <th onClick={() => handleSort('patch')}>Patch</th>
                <th onClick={() => handleSort('firstHerald')}>First Herald</th>
                <th onClick={() => handleSort('firstDragon')}>First Dragon</th>
                <th onClick={() => handleSort('firstTower')}>First Tower</th>
                <th onClick={() => handleSort('firstBlood')}>First Blood</th>
                <th onClick={() => handleSort('firstVoidgrub')}>First VoidGrubs</th>
                <th onClick={() => handleSort('voidGrubKilled')}>VoidGrub Killed</th>
                <th onClick={() => handleSort('TopTurretPlates')}>Top Plates</th>
                <th onClick={() => handleSort('MidTurretPlates')}>Mid Plates</th>
                <th onClick={() => handleSort('BotTurretPlates')}>Bot Plates</th>
                <th onClick={() => handleSort('TeamGoldDiff8')}>GD@8</th>
                <th onClick={() => handleSort('TeamCsDiff8')}>CSD@8</th>
                <th onClick={() => handleSort('TeamExpDiff8')}>XPD@8</th>
                <th onClick={() => handleSort('TeamGoldDiff14')}>GD@14</th>
                <th onClick={() => handleSort('TeamCsDiff14')}>CSD@14</th>
                <th onClick={() => handleSort('TeamExpDiff14')}>XPD@14</th>
                <th onClick={() => handleSort('dragonsBefore14')}>Dragons @14</th>
                <th onClick={() => handleSort('towersBefore14')}>Towers @14</th>
                <th onClick={() => handleSort('JungleProximity')}>Jungle %</th>
                <th onClick={() => handleSort('DuoProximity')}>Duo %</th>
                <th>{"->"}</th>
              </tr>
            </thead>
            <tbody>
              {filterTeamData
                .slice()
                .sort(compareValues(sortedColumn, sortDirection)) // Sort the data
                .map((record, index) => (
                  <tr key={index}>
                      <td style={{ cursor: 'pointer', textAlign: 'left' }}>
                    <Link  to={`/team/${record.EnemyTeamName}`} style={{textDecoration: "none", color: 'inherit', textAlign: 'left', alignItems: 'center', display: 'block'}}>
                        <img className="small-image" src={TeamIcons[record.EnemyTeamName]} alt=""/>  {record.EnemyTeamName}
                    </Link>
                      </td>
                    <td style={{backgroundColor: record.side === "blue" ? 'rgb(153, 153, 234)' : 'rgb(255, 186, 186)'}}>{record.side}</td>
                    <td>{formatGameDuration(record.GameDuration)}</td>
                    <td>{record.winner ? 'Yes' : 'No'}</td>
                    <td>{record.patch}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.firstHerald, getMaxValue('firstHerald')) }}>{record.firstHerald.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.firstDragon, getMaxValue('firstDragon')) }}>{record.firstDragon.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.firstTower, getMaxValue('firstTower')) }}>{record.firstTower.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.firstBlood, 1) }}>{record.firstBlood.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.firstVoidgrub, 1) }}>{record.firstVoidgrub.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.voidGrubKilled, 6) }}>{record.voidGrubKilled.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.TopTurretPlates, getMaxValue('TopTurretPlates')) }}>{record.TopTurretPlates.toFixed(2)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.MidTurretPlates, getMaxValue('MidTurretPlates')) }}>{record.MidTurretPlates.toFixed(2)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.BotTurretPlates, getMaxValue('BotTurretPlates')) }}>{record.BotTurretPlates.toFixed(2)}</td>

                    <td style={{ backgroundColor: getRedGreenCell(record.TeamGoldDiff8,  getMinValue('TeamGoldDiff8'), getMaxValue('TeamGoldDiff8'))}}>{record.TeamGoldDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.TeamCsDiff8, getMinValue('TeamCsDiff8'), getMaxValue('TeamCsDiff8'))}}>{record.TeamCsDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.TeamExpDiff8, getMinValue('TeamExpDiff8'), getMaxValue('TeamExpDiff8'))}}>{record.TeamExpDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.TeamGoldDiff14, getMinValue('TeamGoldDiff14'), getMaxValue('TeamGoldDiff14'))}}>{record.TeamGoldDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.TeamCsDiff14, getMinValue('TeamCsDiff14'), getMaxValue('TeamCsDiff14'))}}>{record.TeamCsDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.TeamExpDiff14, getMinValue('TeamExpDiff14'), getMaxValue('TeamExpDiff14'))}}>{record.TeamExpDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.dragonsBefore14, getMaxValue('dragonsBefore14')) }}>{record.dragonsBefore14.toFixed(1)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.towersBefore14, getMaxValue('towersBefore14')) }}>{record.towersBefore14.toFixed(1)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.JungleProximity, getMinValue('JungleProximity'), getMaxValue('JungleProximity'))}}>{(record.JungleProximity.toFixed(4)*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.DuoProximity, getMinValue('DuoProximity'), getMaxValue('DuoProximity'))}}>{(record.DuoProximity.toFixed(4)*100).toFixed(2)}%</td>
                    <td style={{ cursor: 'pointer' }} onClick={() => handleMatchClick(record.scrimId)}>{"->"}</td>
                  </tr>
              ))}
            </tbody>
            <tfoot style={{borderTop: "1px solid black", backgroundColor: "white"}}>
              <tr style={{borderTop: "1px solid black"}}>
                <td></td> {/* Leave this empty for the Game Duration column */}
                <td>Average</td>
                <td></td> {/* Leave this empty for text columns */}
                <td>{(averageValue('winner')*100).toFixed(2)}%</td> {/* Leave this empty for text columns */}
                <td></td> {/* Leave this empty for Winner column */}
                <td>{(averageValue('firstHerald')*100).toFixed(2)}%</td> {/* Leave this empty for non-numeric columns */}
                <td>{(averageValue('firstDragon')*100).toFixed(2)}%</td> {/* Leave this empty for non-numeric columns */}
                <td>{(averageValue('firstTower')*100).toFixed(2)}%</td> {/* Leave this empty for non-numeric columns */}
                <td>{(averageValue('firstBlood')*100).toFixed(2)}%</td> {/* Leave this empty for non-numeric columns */}
                <td>{(averageValue('firstVoidgrub')*100).toFixed(2)}%</td> {/* Leave this empty for non-numeric columns */}
                <td>{(averageValue('voidGrubKilled')).toFixed(2)}</td> {/* Leave this empty for non-numeric columns */}
                <td>{averageValue('TopTurretPlates').toFixed(2)}</td>
                <td>{averageValue('MidTurretPlates').toFixed(2)}</td>
                <td>{averageValue('BotTurretPlates').toFixed(2)}</td>
                <td>{averageValue('TeamGoldDiff8').toFixed(0)}</td>
                <td>{averageValue('TeamCsDiff8').toFixed(0)}</td>
                <td>{averageValue('TeamExpDiff8').toFixed(0)}</td>
                <td>{averageValue('TeamGoldDiff14').toFixed(0)}</td>
                <td>{averageValue('TeamCsDiff14').toFixed(0)}</td>
                <td>{averageValue('TeamExpDiff14').toFixed(0)}</td>
                <td>{averageValue('dragonsBefore14').toFixed(2)}</td>
                <td>{averageValue('towersBefore14').toFixed(2)}</td>
                <td>{(averageValue('JungleProximity')*100).toFixed(2)}%</td>
                <td>{(averageValue('DuoProximity')*100).toFixed(2)}%</td>
              </tr>
            </tfoot>
          </table>
        </div>

        ) : (
          <LoadingComponent/>
        )}
      </div>
      <ChampionPoolComponnent playerData={filteredPlayerChampionData}/>
    </div>
  );
};

export default TeamComponent;
